import axios from "axios";

// Istanza axios con settaggi standard (definiti nel `.env`)
export const axiosInstance = axios.create(
    {   
        baseURL         :   `${import.meta.env.VITE_HOST}`, 
        timeout         :   import.meta.env.VITE_TIMEOUT,
        withCredentials :   true
    });

// Logica pre-request
const requestInterceptor = request =>
{
    // Settaggi per l'inibizione dell'uso della cache nelle chiamate API
    request.headers['Cache-Control'] = 'no-cache, no-store, must-revalidate';
    request.headers['Pragma'] = 'no-cache';
    request.headers['Expires'] = '0';
    return request;
}

// Logica post-response
const responseInterceptor = response =>
{
    // Si aggiunge la chiave `success` con valore `true` alla response
    response["success"] = true;
    console.log("AXIOS RESP: ", JSON.stringify(response, null, 5))
    return response;
}

// Logica di gestione degli errori nella response
const errorInterceptor = error =>
{
    console.log("AXIOS ERR: ", error)
    // Diverse fattispecie di errori Axios:
    // -    Errore non precisato: assenti le proprietà `response` e `request` ed anche lo `status`.
    // -    Errore restituito dal server mediante response: presenta sia le proprietà `response` che `request`, oltre che lo `status`.
    // -    Errore restituito dal browser, riconducibile alla rete o ad una `bad request`: manca della proprietà `response`, avendo solo la proprietà `request`.
    const response = { "success" : false, "errorBy" : "unknown" };
    if (error.response)
    {
        response.errorBy = "server";
        response["data"] = error.response.data;
    }
    else if (error.request)
        response.errorBy = "network";
    if (error.config?.custom?.dontReject)
        return Promise.resolve(response);
    else
        return Promise.reject(response);
}

// axiosInstance.interceptors.request.use(requestInterceptor);
axiosInstance.interceptors.response.use(responseInterceptor, errorInterceptor);