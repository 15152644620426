import { defineStore } from "pinia";
import { watch } from "vue";

import { useDeviceStore } from "./deviceStore";

import { saveErrors, timeForApiResponseMsgIfSuccess } from "@/assets/configData/constants";
import { objDeepClone } from "@/assets/utils/objectHandlers";

export const useErrorHubStore = defineStore(
    {
        id      :   "errorHubStore",
        state   :   () =>
                    ({
                        networkError    :   false,
                        messageToShow   :   false,
                        messageObj      :   {},
                        isApiResponse   :   false,
                        timedMsgTimeOut :   null
                    }),
        actions :   {
                        resetWatcherOnIsValidDevice()
                        {
                            if (this._isValidDeviceWatcherStopper)
                            {
                                this._isValidDeviceWatcherStopper();
                                this._isValidDeviceWatcherStopper = null;
                            }
                        },

                        setWatcherOnIsValidDevice()
                        {
                            console.log("Settaggio del watcher sul flag di validità del device in uso.");
                            this._isValidDeviceWatcherStopper = watch( () =>
                                useDeviceStore().isValidDevice, (newValue, oldValue) =>
                                    {
                                        if ((newValue !== oldValue) && !this.networkError)
                                        {
                                            if (!newValue)
                                            {
                                                // Il device è appena risultato non valido
                                                console.log("Il device è appena risultato non valido");
                                                let messageIndexes = [ 0 ];
                                                if (useDeviceStore().validAtLeastOnce())
                                                    messageIndexes = [ 1 ];
                                                this.setDeviceError(messageIndexes);
                                            }
                                            else
                                            {
                                                // Il device è tornato valido
                                                console.log("Il device è tornato valido");
                                                this.messageToShow = false;
                                                this.messageObj = {};
                                            }
                                        }
                                    });
                        },

                        initErrorHub()
                        {
                            this.setWatcherOnIsValidDevice();
                            if (saveErrors)
                                this._errorHubCollection = [];
                        },

                        prepareToUnload()
                        {
                            this.resetTimedMsgTimeOut();
                            this.resetWatcherOnIsValidDevice();
                        },

                        pushError(errorObj)
                        {
                            if (saveErrors)
                                this._errorHubCollection.push({ ...errorObj, time : Date.now() });
                            console.log("ERROR ARRAY: ", JSON.stringify(this._errorHubCollection))
                        },

                        setNetworkError()
                        {
                            // Si resetta preliminarmente `messageToShow` per rimuovere eventuali altri messaggi prima di mostrare il network error message
                            this.messageToShow = false;
                            this.resetTimedMsgTimeOut();
                            this.networkError = true;
                            this.messageObj = { type : "critical", indexes : [ 0 ], "clickIsRequired" : true };
                            this.messageToShow = true;
                        },

                        // N.B. Questo metodo può essere chiamato solo dal watcher incaricato
                        setDeviceError(indexes)
                        {
                            // Si resetta preliminarmente `messageToShow` per rimuovere eventuali altri messaggi prima di mostrare il device error message
                            this.messageToShow = false;
                            this.resetTimedMsgTimeOut();
                            this.messageObj = { type : "device", indexes : indexes };
                            this.messageToShow = true;
                        },

                        resetTimedMsgTimeOut()
                        {
                            if (this.timedMsgTimeOut)
                            {
                                clearTimeout(this.timedMsgTimeOut);
                                this.timedMsgTimeOut = null;
                            }
                        },

                        message(set, msgObj = {}, apiResponse = false)
                        {
                            if (this.networkError || this.messageObj.type === "device")
                                return;
                            if (set)
                            {
                                // Si resetta preliminarmente `messageToShow` per rimuovere eventuali altri messaggi prima di mostrare l'ultimo
                                this.messageToShow = false;
                                this.isApiResponse = apiResponse;
                                this.messageObj = objDeepClone(msgObj);
                                if (!this.messageObj.clickIsRequired)
                                {
                                    // Se il campo `clickIsRequired` è assente o comunque falsy significa che il messaggio non verrà concluso con un click su apposito pulsante, bensì, essendo temporizzato, svanirà dopo un tempo specificato dall'apposita costante.
                                    this.resetTimedMsgTimeOut();
                                    this.timedMsgTimeOut = setTimeout( () => 
                                        {
                                            this.messageToShow = false;
                                            this.timedMsgTimeOut = null;
                                        }, timeForApiResponseMsgIfSuccess);
                                }
                                this.messageToShow = true;
                                console.log("Message has been set... ", JSON.stringify(this.messageObj, null, 3))
                            }
                            else
                            {
                                this.messageToShow = false;
                                this.isApiResponse = false;
                                this.messageObj = {};
                                console.log("Message has been reset");
                            }
                        },
                    }
    });