<script setup>
    import { computed, ref } from "vue";
    import { RouterLink } from "vue-router";
    import { useI18n } from "vue-i18n";

    import { useDeviceStore } from "../pinia/deviceStore";
    import { useNavigationStore } from "../pinia/navigationStore";

    import mainMenuConfig from "@/assets/static/json/menusConfigurations/mainMenuConfig.json";

    const icons = ref({});
    const { t } = useI18n();

    const deviceStore = useDeviceStore();
    const navigationStore = useNavigationStore();

    const populateIcons = () => 
    {
        mainMenuConfig.forEach( ({ localeLabel, notAPage }) =>
            {
                const iconsObj = {};
                [ "svg", "png" ].forEach( iconExt =>
                    {
                        iconsObj[iconExt] = `/images/${iconExt}/${localeLabel}.${iconExt}`;
                        (notAPage) || (iconsObj[`active-${iconExt}`] = `/images/${iconExt}/${localeLabel}-active.${iconExt}`);         
                    });
                icons.value[localeLabel] = iconsObj
            });
    };
    
    populateIcons();

    const handleIconError = (event, label, isActive) => event.target.src = icons.value[label][`${isActive ? 'active-png' : 'png'}`];

    const mainMenuItemStyle = computed( () => 
    {
        const styleObj =
        {
            // Nella proprietà `paddingRight`, mantenere il riferimento a `--mainMenuItemHeightDesktop` a prescindere dal dispositivo in uso
            width       :   `var(--mainMenuItemWidth${deviceStore.returnDeviceStringCFL})`,
            height      :   `var(--mainMenuItemHeight${deviceStore.returnDeviceStringCFL})`,
        }
        if (deviceStore.isDeviceTablet)
            styleObj["paddingRight"] = `calc(var(--mainMenuItemHeightDesktop) * var(--menuItemFinePositioningCoefficient))`;
        return styleObj;
    });
</script>

<template>
    <ul id="navbarView"
        :style="
            { 
                flexDirection   :   deviceStore.isDeviceTablet ? 'row' : 'column',
                padding         :   `0 var(--navPaddingX${deviceStore.returnDeviceStringCFL})`,
                gap             :   `var(--navGap${deviceStore.returnDeviceStringCFL})`
            }"
    >
        <component class="mainMenuItem" tabindex="-1"
            v-for="menuItem in mainMenuConfig"
            :key="`mainMenuItem-${menuItem.localeLabel}-${menuItem.id}`"
            :is="menuItem.notAPage ? 'li' : 'router-link'"
            :to="menuItem.notAPage ? undefined : { name : menuItem.localeLabel, replace : false }"
            :class="
                [
                    menuItem.id === navigationStore.activeMenuItem ? 'active' : ''
                ]"
            :style="
                [
                    mainMenuItemStyle,
                    {
                        ...menuItem.specialStyle ? menuItem.specialStyle[deviceStore.returnDeviceString] : {}
                    }
                ]"
        >
            <div class="menuItemIconBox">
                <img class="menuItemIcon" 
                    :src="icons[menuItem.localeLabel][`${(menuItem.id === navigationStore.activeMenuItem) ? 'active-svg' : 'svg'}`]"
                    :alt="`Icona relativa al menù item ${menuItem.localeLabel}${(menuItem.id === navigationStore.activeMenuItem) ? ' <active>' : ''}`"
                    @error="handleIconError($event, menuItem.localeLabel, (menuItem.id === navigationStore.activeMenuItem))"
                >
            </div>
            <span class="menuItemText"
                v-if="!deviceStore.isDeviceTablet || (menuItem.id === navigationStore.activeMenuItem) || menuItem.textAlways"
            >
                {{ t(`menu.${menuItem.localeLabel}`) }}
            </span>
        </component>
    </ul>
</template>

<style lang="scss" scoped>
    @use "../assets/style/scss/main.scss" as *;

    #navbarView
    {
        width: 100%;
        height: 100%;
        display: flex;
        justify-content: start;
        align-items: center;
        list-style-type: none;

        .mainMenuItem
        {
            display: flex;
            justify-content: start;
            align-items: center;
            color: var(--mainMenuItemColor);
            background-color: var(--mainMenuItemBGColor);
            border-radius: var(--menuItemBorderRadius);

            &.active
            {
                color: var(--mainMenuItemActiveColor);
                background-color: var(--mainMenuItemActiveBGColor);
            }

            .menuItemIconBox
            {
                height: 100%;
                aspect-ratio: 1;
                display: flex;
                justify-content: center;
                align-items: center;

                .menuItemIcon
                {
                    object-fit: contain;
                    height: 50%;
                }
            }

            .menuItemText
            {
                font-size: var(--textSize4);
                font-weight: 600;
            }            
        }
    }
</style>