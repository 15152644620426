import { defineStore } from "pinia";

import { useErrorHubStore } from "./errorHubStore";

import { stdContentType } from "@/assets/configData/constants";
import { objDeepClone } from "@/assets/utils/objectHandlers";
import { axiosInstance } from "@/assets/utils/axiosClient";
import { isAValidNumber, dataTypeTransform } from "@/assets/utils/generalUtils";
import apiEndpoints from "@/assets/static/yaml/config/apiEndpoints.yml";

export const useApiStore = defineStore(
    {
        id      :   "apiStore",
        state   :   () =>
                    ({
                        apiData                     :   {},
                        tokenExpiresAt              :   null,
                        processesRequiringLoader    :   0
                    }),
        actions :   {
                        // Metodo invocato a seguito di chiamata API conclusasi con `success false` ed incaricato di notificare al backend i dati relativi alla stessa
                        apiNotifyErrorCall(label, responseData)
                        {
                            const message = responseData?.message;
                            const sensitive =
                            {
                                error_message           :   message,
                                generating_call_name    :   this.getApiConfigObj(label.toUpperCase()).endpoint,
                                date                    :   Date.now()
                            };
                            const ifSuccess =
                            {
                                save            :   false,
                                errorOnMissing  :   false
                            };
                            this.apiUnrejectedCall("ERRORS", false, sensitive, ifSuccess)
                                .then( res => 
                                    {
                                        if (!res.success)
                                            useErrorHubStore().pushError(
                                                {
                                                    from    :   "apiStore/apiNotifyErrorCall",
                                                    error   :   `Error on notifying API response error [ ${message} ] for call on label [ ${label} ]`
                                                });
                                    });
                        },

                        // Metodo utilizzato per le chiamate API gestite in modalità `unrejected`
                        // - `label` identifica l'oggetto relativo all'endpoint della chiamata
                        // - `sensitive` è un oggetto contenente tutti i dati sensibili, da passare direttamente al metodo
                        // - `ifSuccess.save` indica al metodo se salvare i dati ottenuti dalla response (solo quelli attesi)
                        // - `ifSuccess.errorOnMissing` indica al metodo se lanciare un errore generale `throw` in caso di assenza, nella response, di dati attesi
                        apiUnrejectedCall(label, requiresLoader = false, sensitive = {}, ifSuccess = { save : true, errorOnMissing : true })
                        {
                            if (requiresLoader)
                                this.processRequiringLoaderStarts();
                            const configObj = this.getApiConfigObj(label.toUpperCase());
                            // Costruzione dell'oggetto di configurazione della chiamata Axios
                            const requestObj =
                            {
                                url     :   configObj.endpoint,
                                method  :   configObj.method, 
                                data    :   {},
                                headers :   {
                                                "Content-Type"  :   configObj.contentType ?? stdContentType
                                            },
                                params  :   {},
                                custom  :   {
                                                dontReject      :   true
                                            } 
                            };
                            // Se l'oggetto identificativo della chiamata contiene il campo `request`, significa che vi sono dati da compilare nel'oggetto Axios
                            if (configObj.request)
                            {
                                // Acquisizione delle singole chiavi di tipologia del campo `request`
                                const requestKeys = Object.keys(configObj.request);
                                requestKeys.forEach( key =>
                                    {
                                        // Per la chiave di tipologia `sensitive` i dati vengono ricavati dall'oggetto `sensitive` passato come argomento, per le altre chiavi di tipologia invece il dato è ricavato dalla proprietà `apiData`
                                        const dataSource = (key === "sensitive") ? objDeepClone(sensitive) : objDeepClone(this.apiData);
                                        // Si passano in rassegna le singole proprietà relative alla chiave di tipologia corrente
                                        const reqPropertiesArray = configObj.request[key];
                                        reqPropertiesArray.forEach( property =>
                                            {
                                                // Per quella che è la struttura degli oggetti definiti nel file `apiEndpoints.yml`, onde evitare incidenti derivanti da omonimia di proprietà si ha la seguente organizzazione dei dati...
                                                // - i dati per i quali non vi è rischio di omonimia, sono salvati direttamente con il proprio nome (esempio: email)
                                                // - i dati, come ad esempio i vari `id`, per i quali vi è rischio di omonimia, vengono salvati con il nome associato al campo `as` del dato stesso
                                                let propertyKey = null;
                                                let propertyVal = null;
                                                if (typeof property === "object")
                                                {
                                                    propertyKey = Object.keys(property)[0];
                                                    const { as } = property[propertyKey];
                                                    propertyVal = dataSource[as];
                                                }
                                                else
                                                {
                                                    propertyKey = property;
                                                    propertyVal = dataSource[property];
                                                }
                                                // A seconda della chiave di tipologia, il dato viene memorizzato nell'oggetto di configurazione per la chiamata Axios, sotto il campo corretto
                                                if ((key === "body") || (key === "sensitive"))
                                                    requestObj.data[propertyKey] = propertyVal;
                                                else if (key === "queryParams")
                                                    requestObj.params[propertyKey] = propertyVal;   
                                                else if (key === "urlParams")
                                                    requestObj.url = requestObj.url.replace(`:${propertyKey}`, propertyVal);
                                            });
                                    });
                            }
                            console.log("READY TO API CALL... ", JSON.stringify(requestObj, null, 5))
                            // Il metodo restituisce la chiamata Axios la quale, essendo di tipo `unrejected`, può essere interamente gestita nel solo `.then`
                            return axiosInstance.request(requestObj).then( ({ success, status, data, errorBy }) => 
                                {
                                    // In caso di chiamata con response di successo e solo nel caso in cui sia richiesto il salvataggio dei dati o il lancio di errore in assenza di dati attesi, si effettuano tali operazioni
                                    const { save, errorOnMissing } = ifSuccess;
                                    // ******TESTING******
                                    if (success)
                                    {
                                        // console.log("OBJECT DATA..... ", JSON.stringify(data, null, 5))
                                        console.log("TYPE OF STRATEGY-DATA... ", typeof data.strategy_data)
                                        console.log("TYPE OF ACCOUNT-DATA... ", typeof data.account_data)
                                        console.log("TIPO.... ", typeof data.tokenExpiresAt)
                                        console.log("RISULTATO EXPIRESAT: ", new Date(parseInt(data.tokenExpiresAt)))
                                        console.log("VALORE EXPIRESAT..... ", data.tokenExpiresAt)
                                        const ima = Date.now();
                                        if (parseInt(data.tokenExpiresAt) <= ima)
                                            data.tokenExpiresAt = (ima + (1000 * 60 * 1)).toString();
                                    }
                                    // *******************
                                    if (success && (save || errorOnMissing) && configObj.response)
                                    {
                                        // Se risposta API con successo e necessità di salvare i dati attesi e/o lanciare un errore in loro assenza (totale o parziale)
                                        const missingInResponse = [];
                                        const responseKeys = Object.keys(configObj.response);
                                        responseKeys.forEach( key =>
                                            {
                                                // Se la chiave è `nonSensitive` significa che il dato può essere salvato
                                                if (key.startsWith("nonSensitive"))
                                                {
                                                    const resPropertiesArray = configObj.response[key];
                                                    resPropertiesArray.forEach( property => 
                                                        {
                                                            // `typeof property === "string"` significa che il dato non è a rischio omonimia
                                                            if (typeof property === "string")
                                                            {
                                                                // Se il dato atteso è presente nella response e `save` vale true, il dato viene salvato
                                                                if (data?.hasOwnProperty(property) && save)
                                                                {
                                                                    // Se nell'oggetto configuratore è presente la proprietà, accompagnata dal tipo specifico richiesto, la si converte
                                                                    if (configObj.responseDataType)
                                                                    {
                                                                        const itemObj = configObj.responseDataType.find( obj => (Object.keys(obj).includes(property)));
                                                                        if (itemObj && (typeof data[property] !== itemObj[property]))
                                                                            data[property] = dataTypeTransform(data[property], itemObj[property]);
                                                                    }
                                                                    if (key === "nonSensitiveSpecific")
                                                                        this.setStoreProperty(property, data[property]);
                                                                    else if (key === "nonSensitiveGlobal")
                                                                        this.setApiData({ [property] : data[property] });
                                                                }
                                                                // Se il dato atteso non è presente nella response lo si aggiunge all'array dei dati assenti
                                                                else if (!(data?.hasOwnProperty(property)))
                                                                    missingInResponse.push({ MissingData : property });
                                                            }
                                                            // Aggiungere il caso `typeof property === "object"` per i dati a rischio di omonimia, recuperando il nome della variabile in cui salvarli, direttamente destrutturando il campo `as`
                                                        });
                                                }
                                            });
                                        // Si salva comunque l'informazione relativa all'assenza di dati attesi in useErrorHub
                                        if (missingInResponse.length !== 0)
                                            useErrorHubStore().pushError(
                                                {
                                                    from    :   "apiStore/apiUnrejectedCall",
                                                    error   :   `Success on API call, label [ ${label} ] but invalid response for missing data [ ${missingInResponse} ]`
                                                });
                                        // Se è richiesto di lanciare un errore in assenza dei dati e vi sono dati assenti allora l'errore viene lanciato
                                        if (errorOnMissing && (missingInResponse.length !== 0))
                                            throw new Error(`Missing data into the API response: ${JSON.stringify(missingInResponse)}`);
                                    }
                                    else if (!success)
                                    {
                                        useErrorHubStore().pushError(
                                            {
                                                from    :   "apiStore/apiUnrejectedCall",
                                                error   :   `API call, label [ ${label} ] failed. Error by [ ${errorBy} ]. Error data [ ${data} ]`
                                            });
                                    }
                                    if (requiresLoader)
                                        this.processRequiringLoaderEnds();
                                    return { success, status, data, errorBy };
                                });
                        },
  
                        // Metodo che restituisce l'intero oggetto relativo all'endpoint la cui label è passata come parametro
                        getApiConfigObj : label => apiEndpoints.find( item => item.label === label),

                        // Metodo atto ad aggiornare l'intera proprietà `apiData` oppure sue singole sotto-proprietà
                        setApiData(dataObj, whole = false)
                        {
                            console.log("SETTING APIDATA..... ", JSON.stringify(dataObj, null, 3))
                            if (whole)
                                this.apiData = objDeepClone(dataObj);
                            else
                                for (const property in dataObj)
                                    this.apiData[property] = objDeepClone(dataObj[property]);
                        },

                        // Metodo atto ad aggiornare una specifica proprietà (se già definita) dello store corrente
                        setStoreProperty(propertyName, propertyValue)
                        {
                            if (this.$state.hasOwnProperty(propertyName))
                                this[propertyName] = objDeepClone(propertyValue);
                        },

                        processRequiringLoaderStarts()
                        {
                            this.processesRequiringLoader++;
                        },

                        processRequiringLoaderEnds()
                        {
                            this.processesRequiringLoader--;
                        }
                    },
        getters :   {
                        // Getter che restituisce il valore booleano indicante lo stato di `logged in` dello user
                        isUserLoggedIn : (state) => isAValidNumber(state.tokenExpiresAt)
                    }
    }
);