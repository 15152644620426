<script setup>
    import { onBeforeMount, ref, watch, computed } from "vue";
    import { RouterView, useRouter, useRoute } from "vue-router";

    import { useMainStore } from "./pinia/mainStore";
    import { useDeviceStore } from "./pinia/deviceStore";
    import { useNavigationStore } from "./pinia/navigationStore";  
    import { useErrorHubStore } from "./pinia/errorHubStore";
    import { useLocaleStore } from "./pinia/localeStore";

    import CompHeader from "./components/CompHeader.vue";
    import CompMessageViewer from "./components/CompMessageViewer.vue";

    import BTFLogo from "@/assets/static/images/png/logo.png";

    import { capitalizeFirstLetter } from "./assets/utils/generalUtils";

    const routerReady = ref(false);
    const route = useRoute();
    const router = useRouter();
    const mainStore = useMainStore();
    const deviceStore = useDeviceStore();
    const navigationStore = useNavigationStore();
    const errorHubStore = useErrorHubStore();
    const localeStore = useLocaleStore();
    const logo = ref(BTFLogo);

    console.log("/////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////");
    console.log("/////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////");
    console.log("/////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////");
    console.log("/////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////");
    console.log("/////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////");

    // **************************************************************************************************
    // Inizio del blocco logico di avvio (in tutte le condizioni)

    // *************************************************
    // Blocco di indirizzamento alla rotta corretta (solo per fase di inizializzazione piattaforma)
    onBeforeMount( async () => 
        {
            await router.isReady();
            routerReady.value = true;
            console.log("ROUTER PRONTO")
        });

    let firstAccessRouteWatcherStopper = null;

    const stopFirstAccessRouteWatcher = () => 
    {
        if (firstAccessRouteWatcherStopper)
        {
            firstAccessRouteWatcherStopper();
            firstAccessRouteWatcherStopper = null;
            console.log("WATCHER SUI PARAMETRI DI PRIMO ACCESSO DISABILITATO - PROCEDURA DI ACCESSO ULTIMATA")
        }
    };

    firstAccessRouteWatcherStopper = watch(
        [ routerReady, () => mainStore.isInitialized, () => mainStore.keepWaiting ],
        ([ isRouterReady, isInitialized, isKeepWaiting ]) =>
            {
                if (isRouterReady)
                {
                    if (!navigationStore.initialized)
                        navigationStore.initNavigationStore(route, router);
                    if (mainStore.isRestricted)
                    {
                        navigationStore.manageRestrictedNavigation();
                        stopFirstAccessRouteWatcher();
                    }
                    else
                    {
                        if (!isInitialized)
                            navigationStore.managePlatformAccess(isKeepWaiting);
                        else
                        {
                            if (!isKeepWaiting)
                            {
                                // Piattaforma inizializzata
                                navigationStore.managePlatformLanding();
                                stopFirstAccessRouteWatcher();
                            }
                        }
                    }
                }
            }, { immediate : true });
    // *************************************************

    // *************************************************
    // Blocco di gestione delle attività pre-unload
    function prepareToUnload()
    {
        console.log("PREPARE TO UNLOAD - PRINCIPALE (VUE.APP)")
        mainStore.prepareToUnload({ name: route.name, path: route.path });
        stopFirstAccessRouteWatcher();
        navigationStore.resetWatcherOnRoute();
    };

    window.addEventListener("beforeunload", () => prepareToUnload());
    // *************************************************

    mainStore.initialization();
// Fine del blocco logico di avvio (in tutte le condizioni)
// **************************************************************************************************

// **************************************************************************************************
// Inizio del blocco di codice Javascript correlato al rendering
    const platformPagesStyle = computed( () =>
    {
        // Essendo l'invocazione di questa computed property protetta dalla condizione di device valido non si pongono problemi circa il valore restituito da `deviceStore.returnDeviceStringCFL`
        const capDevice = deviceStore.returnDeviceStringCFL;
        const capRoute = capitalizeFirstLetter(navigationStore.route.name);

        const styleObj = {};

        if (!navigationStore.pageHasMainMenu)
        {
            // Formattazione dell'oggetto di stile per la `platformPage` per le pagine non protette
            styleObj["flexDirection"] = "column";
            styleObj["backgroundColor"] = "var(--loginBGColor)";
            styleObj["width"] = `var(--page${capRoute}WidthPerc${capDevice})`;
            styleObj["padding"] = `var(--page${capRoute}PaddingTopPage${capDevice}) var(--page${capRoute}PaddingXPage${capDevice}) var(--page${capRoute}PaddingBottomPage${capDevice})`;
        }
        else
        {
            // Formattazione dell'oggetto di stile per la `platformPage` con valori basati su pagine protette
            styleObj["width"] = `var(--pageProtectedWidthPerc${capDevice})`;
            styleObj["height"] = "100%";
            styleObj["padding"] = `var(--pageProtectedPaddingTopPage${capDevice}) var(--pageProtectedPaddingXPage${capDevice}) var(--pageProtectedPaddingBottomPage${capDevice})`;
        }
        return styleObj;
    });

    const headerStyle = computed( () =>
    {
        // Essendo l'invocazione di questa computed property protetta dalla condizione di device valido non si pongono problemi circa il valore restituito da `deviceStore.returnDeviceStringCFL`
        const capDevice = deviceStore.returnDeviceStringCFL;
        const capRoute = capitalizeFirstLetter(navigationStore.route.name);

        const styleObj = {};

        if (navigationStore.pageHasMainMenu)
        {
            if (deviceStore.isDeviceTablet)
            {
                styleObj["width"] = "100%";
                styleObj["height"] = "var(--headerHeightTablet)";
            }
            else
            {
                styleObj["width"] = "var(--headerWidthDesktop)";
                styleObj["height"] = "100%";
            }
        }
        else
        {
            styleObj["width"] = "100%";
            styleObj["height"] = `var(--page${capRoute}HeaderHeight${capDevice})`;
        }
        return styleObj;
    });

    const mainStyle = computed( () => 
    {
        const styleObj = {};

        if (navigationStore.pageHasMainMenu)
        {
            if (deviceStore.isDeviceTablet)
            {
                styleObj["width"] = "100%";
                styleObj["height"] = "var(--mainHeightTablet)";
            }
            else
            {
                styleObj["width"] = "var(--mainWidthDesktop)";
                styleObj["height"] = "100%";
            }
        }
        else
        {
            styleObj["width"] = "100%";
        }
        return styleObj;
    });
// Fine del blocco di codice Javascript correlato al rendering
// **************************************************************************************************
</script>

<template>
    <div id="viewport">

        <div id="overlay"
            v-show="mainStore.isOverlayOn"
            :style="{ backgroundColor : `rgba(0, 0, 0, 0)` }" 
        >
            <div id="loaderBox"
                v-if="mainStore.isLoaderOn"
            >
                <div id="loader">
                    <img alt="BrainTech Finance Logo"
                        :src="logo"
                    >
                </div>
                <h2 id="animatedText">Loading </h2>
            </div>
        </div>

        <div id="platformView"
            v-if="!mainStore.keepWaiting && navigationStore.initialized"
        >
            <div id="invalidDeviceView"
                v-if="!deviceStore.isValidDevice"
            >
                <h2 v-html="localeStore.returnLocaleString('device')" style="margin: 10px; color: yellow;"></h2>
            </div>
            <div id="platformPages"
                v-else
                :style="platformPagesStyle"
            >
                <header
                    v-if="route.name !== 'root'"
                    :style="headerStyle"
                >
                    <CompHeader />
                </header>
                <main
                    :style="mainStyle"
                >
                    <router-view />
                    <CompMessageViewer 
                        v-if="errorHubStore.messageToShow"
                    />
                </main>
            </div>
        </div>

    </div>
</template>

<style lang="scss">
    @use "./assets/style/scss/main.scss" as *;

    #overlay
    {
        position: fixed;
        width: 100vw;
        height: 100%;
        z-index: 999;

        #loaderBox
        {
            position: absolute;
            top: 50%;
            left: 50%;
            transform: translate(-50%, -50%);
            display: flex;
            justify-content: center;
            align-items: center;
            flex-direction: column;
            gap: 25px;

            #loader
            {
                width: var(--loaderBoxSize);
                height: var(--loaderBoxSize);
                border-radius: 10%;
                position: relative;
                background: linear-gradient(to right, blue, green, red, green, blue);
                background-size: 1000% 1%;
                animation: flipLoader 3.5s linear infinite;

                img
                {
                    width: 100%;
                    object-fit: contain;
                    animation: logoCounterRotation 3.5s linear infinite;
                }
            }

            #animatedText 
            {
                font-size: 2rem;
                color: transparent;
                background-image: linear-gradient(to right, red, green, blue, green, red);
                background-size: 1000% 10%;
                background-clip: text;
                -webkit-background-clip: text;
                animation: textColorFill 3.5s linear infinite;
            }
        }
    }
</style>
