// Per coerenza semantica, tutte le chiavi salvate nel local storage e nel session storage iniziano con una specifica stringa definita in `.env`, sotto la variabile `VITE_APP_NAMESPACE`

// Array con gli storage validi
const validStorages = [ "local", "session" ];

// Funzione che determina la validità dello storage specificato
const isValidStorage = storage => validStorages.includes(storage);

// Funzione incaricata di restituire gli elementi corretti, utili ad operare sullo storage richiesto
const storageObj = where =>
{
  const whereLC = where.toLowerCase();
  if (!isValidStorage(whereLC))
    return {};
  const obj = {};
  if (whereLC === "local")
  {
    obj["storage"] = localStorage;
    obj["prefix"] = "-LS-";
  }
  else
  {
    obj["storage"] = sessionStorage;
    obj["prefix"] = "-SS-";
  }
  return obj;
}

// Funzione che restituisce la stringa completa identificativa della chiave nello storage specifico
const storageFullKey = (prefix, key) => import.meta.env.VITE_APP_NAMESPACE.concat(prefix, key);

// Funzione incaricata di recuperare una specifica coppia `chiave-valore` dallo storage specificato
// Se il dato (valore) è un oggetto serializzato lo si recupera in quanto oggetto, altrimenti lo si restituisce come stringa
// Se il dato non esiste viene restituito il valore `null`
export const storageGetItem = (where, key) =>
{
  const { storage, prefix } = storageObj(where);
  const storageItem = storage.getItem(storageFullKey(prefix, key));
  try
  {
    const parsed = JSON.parse(storageItem);
    // L'assenza di errori implica che l'elemento è un oggetto serializzato oppure non esiste.
    // - Nel primo caso si restituisce l'oggetto in quanto tale
    // - Nel secondo caso viene restituito `null` non inteso come stringa ma come valore
    return parsed;
  }
  catch
  {
    // Il verificarsi di un errore sul JSON.parse implica che l'elemento non è un oggetto serializzato ma una stringa 
    // Si restituisce la stringa in quanto tale
    return storageItem;
  }
}
        
// Funzione incaricata di salvare una specifica coppia `chiave-valore` nello storage specificato
// Se il valore fornito non è coerente, si rimuove il dato dallo storage (laddove presente)
export const storageSetItem = (where, key, value) =>
{
  if ((value !== null) && (value !== undefined))
    {
      // Se il dato fornito è valido lo si salva nel local storage, (serializzandolo in stringa laddove fosse un oggetto)
      const valueAsString = (typeof value === "object") ? JSON.stringify(value) : value;
      const { storage, prefix } = storageObj(where);
      storage.setItem(storageFullKey(prefix, key), valueAsString);
    }
    else
      // In caso contrario si rimuove il dato dallo storage (laddove già presente)
      storageRemoveItem(where, key);
}
        
// Funzione incaricata della rimozione della coppia `chiave-valore` dallo storage specificato
export const storageRemoveItem = (where, key) => 
{
  const { storage, prefix } = storageObj(where);
  storage.removeItem(storageFullKey(prefix, key));
}

// Funzioni operanti sul local storage
export const localStorageGetItem = key => storageGetItem("local", key);
export const localStorageSetItem = (key, value) => storageSetItem("local", key, value);
export const localStorageRemoveItem = key => storageRemoveItem("local", key);

// Funzioni operanti sul session storage
export const sessionStorageGetItem = key => storageGetItem("session", key);
export const sessionStorageSetItem = (key, value) => storageSetItem("session", key, value);
export const sessionStorageRemoveItem = key => storageRemoveItem("session", key);

// Funzioni operanti su tutti gli storage
export const clearStorage = (key, ...storages) => 
{
  for (const storage of storages)
    if (isValidStorage(storage.toLowerCase()))
      storageRemoveItem(storage, key);
}
