<script setup>
    import { ref } from "vue";
    import { useI18n } from "vue-i18n";

    import { useNavigationStore } from "../pinia/navigationStore";
    import { useDeviceStore } from "../pinia/deviceStore";

    import CompNavbar from "./CompNavbar.vue";

    import brandSVG from "@/assets/static/images/svg/brand.svg";
    import brandPNG from "@/assets/static/images/png/brand.png";

    const { t } = useI18n();
    const navigationStore = useNavigationStore();
    const deviceStore = useDeviceStore();
    const brandSrc = ref(brandSVG);
</script>

<template>
    <div id="headerView"
        :class="(!navigationStore.pageHasMainMenu || deviceStore.isDeviceTablet) ? 'headerHorizontal' : 'headerVertical'"
        :style="(navigationStore.pageHasMainMenu && deviceStore.isDeviceTablet) && 
                { 
                    backgroundColor :   'var(--blocksBGColor)', 
                    borderRadius    :   'var(--blocksBorderRadius)'
                }"
    >

        <div id="headerBrand"
            v-if="!(navigationStore.pageHasMainMenu && deviceStore.isDeviceTablet)"
        >
            <h2
                v-if="[ 'register', 'subscription' ].includes(navigationStore.route.name)"
            >
                {{ t("text.welcome") }}
            </h2>
            <div id="brandImgBox" style="display: flex; justify-content: center;">
                <img id="brandImg" alt="Brand Image"
                    :class="(navigationStore.route.name === 'login') ? 'brandForLogin' : ''"
                    :src="brandSrc" 
                    @error="brandSrc = brandPNG"
                >
            </div>
        </div>

        <nav
            v-if="navigationStore.pageHasMainMenu"
        >
            <CompNavbar />
        </nav>

    </div>
</template>

<style lang="scss" scoped>
    @use "../assets/style/scss/main.scss" as *;

    #headerView
    {
        width: 100%;
        height: 100%;
        display: flex;

        &.headerHorizontal
        {
            flex-direction: row;
            justify-content: center;
            align-items: center;

            #headerBrand
            {
                height: 100%;
                display: flex;
                justify-content: center;
                align-items: center;
                column-gap: 3rem;

                h2 
                {
                    color: var(--welcomeColor);
                    font-size: var(--textSize1);
                }

                #brandImgBox
                {
                    align-items: center;
                    height: 100%;

                    #brandImg
                    {
                        width: auto;
                        height: 350%;
                        object-fit: contain;

                        &.brandForLogin
                        {
                            height: 400%;
                        }
                    }
                }

            }
        }

        &.headerVertical
        {
            flex-direction: column;
            justify-content: start;
            align-items: center;

            #headerBrand
            {
                width: 100%;
                overflow: hidden;

                #brandImgBox
                {
                    width: 100%;
                    overflow: hidden;

                    #brandImg
                    {
                        max-width: 90%;
                        object-fit: contain;
                        transform: translateY(-18%);
                    }
                }
            }
        }

        nav
        {
            width: 100%;
            height: 100%;
        }
    }
</style>