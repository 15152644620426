import { createRouter, createWebHistory } from "vue-router";

import { useMainStore } from "./pinia/mainStore";
import { useTimingStore } from "./pinia/timingStore";

import routerPages from "@/assets/static/yaml/config/routerPages.yml";
import { returnRouteData, returnRouteDataByAlias } from "@/assets/utils/navigationUtils";

const routes = routerPages.map( routerPage => 
    {   
        const currentRoute = 
        {
            path        :   returnRouteData(routerPage, "path"),
            name        :   returnRouteData(routerPage, "name"),
            component   :   () => import(`@/pages/${returnRouteData(routerPage, "file")}.vue`),
            meta        :   {
                                pageTitle   :   returnRouteData(routerPage, "title")
                            } 
        }
        const updateObj = returnRouteData(routerPage, "update");
        if (updateObj)
        {
            currentRoute["beforeEnter"] = (to, from) =>
            {
                const timingStore = useTimingStore();
                timingStore.setAutoUpdateApiCallTimer(updateObj);
            }
        }
        // console.log("CHIAVI DELLA ROTTA.... ", JSON.stringify(Object.keys(currentRoute), null, 3))
        return currentRoute;
    });

const router = createRouter({ history : createWebHistory(), routes });

router.beforeEach( (to, from) => 
    {
        console.log("BEFORE EACH GUARD")
        console.log("FROM ROUTE... ", from.path)
        console.log("TO ROUTE... ", to.path)
        const mainStore = useMainStore();
        // Gestione del tentato accesso ad una rotta non esistente
        if (!router.hasRoute(to.name))
        {
            console.log("WRONG ROUTE... ", to.path);
            // Si considerano solo i casi in cui il tentato accesso ad una rotta non esistente avvenga mediante metodi che richiedono il ricaricamento della piattaforma poichè l'unico modo che l'utente ha per tentare l'accesso ad una rotta inesistente senza che ciò richieda il ricaricamento è mediante link interno ma, ovviamente tutti i nostri link interni saranno costruiti in maniera tale da evitare rischi in tal senso.
            // Ne consegue che è sufficiente basarsi sulla sessione per stabilire il redirect da eseguire
            const redirectPath = (mainStore.isBackToSession) ? returnRouteDataByAlias("dashboard", "path") : returnRouteDataByAlias("waiting room", "path");
            return { path : redirectPath };
        }
        else
            return true;
    });
    
router.afterEach( (to, from) => 
    {
        console.log("AFTER EACH GUARD")
        console.log("FROM ROUTE... ", from.path)
        console.log("TO ROUTE... ", to.path)
        document.title = `BrainTech Finance${to.meta.pageTitle ? ` | ${to.meta.pageTitle}` : ''}`;
    });

export { router };