// Lista degli eventi da monitorare per la valutazione dei tempi di inattività
export const eventsToCheck = 
[
    "mousemove",
    "click",
    "wheel"
];

// Tempo massimo consentito all'utente di restare fuori sessione, affinchè possa fruire del relog automatico
// 15 minuti
export const maxExtraSessionTime = 1000 * 60 * 2;
// export const maxExtraSessionTime = 1000 * 60 * 15;

// Mantenere `maxInactivityTime` superiore o al massimo uguale a `maxExtraSessionTime`
// Tempo massimo consentito all'utente di restare inattivo in sessione, affinchè possa fruire del relog automatico
// 1 ora
export const maxInactivityTime = 1000 * 60 * 2;

// Tempo di anticipo del relog rispetto alla scadenza del token
// 10 secondi
export const relogThreshold = 1000 * 10;

// Temporizzatore per i listeners sugli `eventsToCheck` in modalità debounced
export const timerForDebouncedUserEventListener = Math.round(relogThreshold / 10);

// Tempo minimo di attesa in `waiting room` durante la fase di accesso alla piattaforma in modalità `nuova sessione`
// Il valore attualmente impostato è volutamente lungo per questioni di testing
// 7 secondi
export const minWaitingRoomTime = 1000 * 3;

// Flag indicante se gli errori a runtime vadano salvati nell'apposito array di `errorHubStore`
export const saveErrors = true;

// Content-Type standard per le chiamate API
export const stdContentType = "application/json";

// Temporizzatore per il resize event listener in modalità debounced
// 250 millisecondi
export const timerForDebouncedResizeListener = 250;

// La costante `checkDeviceHistory`, se settata a `true`, consente di monitorare se, in alcune situazioni, il device dell'utente è risultato valido.
// Questa informazione torna utile laddove, in un certo momento, il device dovesse risultare non valido, facendoci rendere conto che il device non è `invalido` in sè ma piuttosto lo è diventato a seguito di ridimensionamento della finestra o passaggio da vista `landscape` a vista `portrait`.
export const checkDeviceHistory = true;

export const deferredLogOutCookieName = "def_logout";

export const deferredLogOutCookieAttributes = 
{
    Path        :   "/", 
    SameSite    :   "Strict"  
};

export const tabletMaxWidth = 1365;

export const desktopHDMinWidth = 1441;

// Mantenere questo parametro allineato con il primo tempo della transizione su [ .itemInput:not(.empty) + .itemLabel, .itemInput.itemFocused + .itemLabel ] in floatingLabels.css
export const delayOnDatePickerActivation = 100;

// 18 anni
export const minimumAge = 1000 * 60 * 60 * 24 * 365 * 18;

export const showTimedApiResponseMsgIfSuccess = true;

// 3 secondi
export const timeForApiResponseMsgIfSuccess = 1000 * 3;

export const actualOverlayAlpha = 0.5;

// export const defaultLineChartValues = [ 193.48, 123.41, 174.07, 183.17, 194.11, 130.8, 154.6, 199.85, 130.8 ];
export const defaultLineChartValuestfW = Array.from({ length : 53 }, () => parseFloat((Math.random() * 100).toFixed(2)));
export const defaultLineChartValuestfM = Array.from({ length : 36 }, () => parseFloat((Math.random() * 100).toFixed(2)));
export const defaultLineChartValuestfY = Array.from({ length : 3 }, () => parseFloat((Math.random() * 100).toFixed(2)));
export const defaultDBTab = "tab01";
export const defaultDBTimeFrame = "tfW";
export const storageForDashboardDefaults = "local";


