export const isAValidNumber = (value, options = {}) =>
{
    const { allowZero = false, allowNegatives = false, allowDecimals = false } = options;
    if (value === 0)
        return allowZero;
    if (value && (typeof value === "number") && (!Number.isNaN(value)))
    {
        if ((value < 0) && !allowNegatives)
            return false;
        if (!Number.isInteger(value) && !allowDecimals)
            return false;
        return true;
    }
    return false;
};

export const capitalizeFirstLetter = word => word.charAt(0).toUpperCase() + word.slice(1);

export const dataTypeTransform = (data, dataType) =>
{
    let toBeReturned = data;
    switch (dataType)
    {
        case "number"   :   toBeReturned = Number(data);
                            break;
        case "boolean"  :   toBeReturned = Boolean(data);
                            break;
        case "object"   :   toBeReturned = JSON.parse(data);
                            break;
    }
    return toBeReturned;
}