<script setup>
    import { useErrorHubStore } from '@/pinia/errorHubStore';
    import { useLocaleStore } from "@/pinia/localeStore";

    const errorHubStore = useErrorHubStore();
    const localeStore = useLocaleStore();

    const buttonClicked = () => 
    {
        errorHubStore.message(false);
        if (errorHubStore.networkError)
        {
            history.pushState(null, "", window.location.origin);
            window.location.reload();
        }
    }
</script>

<template>
    <div id="componentMessageViewer">
        <h1 v-html="(errorHubStore.isApiResponse) ? errorHubStore.messageObj.message : localeStore.returnLocaleString(errorHubStore.messageObj.type)"/>
        <button 
            v-if="errorHubStore.messageObj.clickIsRequired"
            @click="buttonClicked"
        >
            OK
        </button>
    </div>
</template>

<style lang="scss">
    @use "@/assets/style/scss/main.scss" as *;

    #componentMessageViewer
    {
        position: absolute;
        z-index: 1000;
        width: 50%;
        height: 50%;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        background-color: darkgray;
        border: 5px solid black;
        color: greenyellow;
        text-align: center;
    }
</style>