import { createI18n } from "vue-i18n";

import localSettings from "@/assets/static/yaml/config/localSettings.yml";

const retrieveFallbackLocale = () =>
{
  console.log("RETRIEVING CONFIGURATION FROM LOCALSETTINGS.YML")
  const localeObj = localSettings.find( ({ key }) => key === "locale");
  if (!localeObj || !localeObj.locales || (localeObj.locales.length === 0))
    throw new Error("File < localSettings.yml > corrotto!");
  else
  {
    const fbLocale = localeObj.locales.find( ({ fallback }) => (fallback === true)) ?? localeObj.locales[0];
    if (fbLocale.value)
      return fbLocale.value;
    else
      throw new Error("File < localSettings.yml > corrotto!");
  }
}

const defaultLocale = retrieveFallbackLocale();

export const i18n = createI18n(
    {
        legacy: false,
        locale: defaultLocale,
        fallbackLocale: defaultLocale,
        messages: {}
    });

export async function loadLocale(locale, isFallbackLocale = false) 
{
  console.log(`Caricamento del locale file ${locale}.yml. ${isFallbackLocale && "Locale di fallback"}`)
    if (Object.keys(i18n.global.getLocaleMessage(locale)).length !== 0) 
    {
      // Locale già caricata
      console.log("Il file di locale è già caricato")
      return;
    }
    try 
    {
      const messages = await import(`@/assets/static/yaml/locales/${locale}.yml`);
      i18n.global.setLocaleMessage(locale, messages.default);
      i18n.global.locale.value = locale;
    } 
    catch (error) 
    {
        console.error(`Errore durante il caricamento del file... ${locale}.yml`);
        throw new Error(`Impossibile caricare il file ${locale}.yml di fallback!`);
    }
}
  