import { defineStore } from "pinia";

import { useApiStore } from "./apiStore";
import { useTimingStore } from "./timingStore";

import * as constants from "@/assets/configData/constants";
import * as storage from "@/assets/utils/storageHandlers";
import { objDeepClone } from "@/assets/utils/objectHandlers";
import { isAValidNumber } from "@/assets/utils/generalUtils";
import apiEndpoints from "@/assets/static/yaml/config/apiEndpoints.yml";

export const useStampsStore = defineStore(
    {
        id      :   "stampsStore",
        state   :   () => 
                    ({
                        isBackToSession             :   false,
                        isBackToUnlogged            :   false,
                        excessiveExtraSessionTime   :   false

                    }),
        actions:    {
                        // Metodo incaricato di restituire un oggetto contenente tutte le coppie chiave-valore che costituiranno il campo `data` dello specifico `stamp`
                        // -    se lo `stamp` è `session` verranno restituiti tutti i dati dell'oggetto `apiData` in `apiStore`
                        // -    per altri valori di `stamp` (esempio `login` e `register`) verranno restituiti solo i dati indicati nello specifico oggetto configuratore, alla chiave `body`, ovvero solo i dati `non sensibili` che vengono inviati al server per la specifica chiamata API
                        returnDataForStamp(stamp)
                        {
                            let returnedObj = {};
                            if (stamp !== "session")
                            {
                                const { body } = useApiStore().getApiConfigObj(stamp.toUpperCase()).request;
                                body.forEach( property => (returnedObj[property] = useApiStore().apiData[property]) );
                            }
                            else
                                returnedObj = objDeepClone(useApiStore().apiData);
                            return returnedObj;
                        },

                        // Metodo incaricato di salvare, nel session storage, uno specifico `stamp` con i relativi campi
                        // Il parametro `fromApi` definisce l'origine dei dati, nei seguenti termini...
                        // - TRUE: i dati vengono recuperati direttamente da `apiData` in `apiStore`
                        // - FALSE: i dati vengono recuperati dal relativo campo dell'oggetto `this._stamps` in `stampsStore`
                        saveStamp(stamp, fromApi = true)
                        {
                            const now = Date.now();
                            console.log(`Salvataggio del ${stamp}Stamp nel session storage. Now: ${now}.`);
                            console.log(`La proprietà "tokenExpiresAt" vale... ${useApiStore().tokenExpiresAt}`);
                            const stampObj =
                            {
                                exitTime        :   now,
                                data            :   (fromApi) ? this.returnDataForStamp(stamp) : this._stamps[stamp]
                            }
                            if (stamp === "session")
                                stampObj["tokenExpiresAt"] = useApiStore().tokenExpiresAt;
                            storage.sessionStorageSetItem(`${stamp}Stamp`, stampObj);
                            console.log(`Salvataggio ${stamp}Stamp = ${JSON.stringify(stampObj, null, 3)}`);
                        },

                        // Metodo che restituisce un array contenente le `label` (in minuscolo), di ciascun oggetto configuratore che abbia `key` tra le sue chiavi
                        returnLabelsForStamp : ( key = "saveStamp" ) => apiEndpoints.filter( obj => obj[key] ).map( ({ label }) => label.toLowerCase()),

                        // Metodo che restituisce `true` solo se lo stamp indicato è diverso da null, ovvero se all'avvio era presente nel session storage
                        checkIfUnloggedStamp(stamp)
                        {
                            return (this._stamps[stamp] !== null);
                        },

                        // Metodo incaricato di settare la proprietà reattiva `isBackToUnlogged` a seconda che, nell'oggetto non reattivo `_stamps` sia presente (truthy) almeno uno `stamp` riconducibile ad una situazione di `abbandono della piattaforma con l'utente non ancora loggato e successivo rientro`
                        checkIfBackToUnlogged()
                        {
                            const labelsForUnlogged = this.returnLabelsForStamp("meansUnlogged");
                            this.isBackToUnlogged = Object.keys(this._stamps).some( key => (labelsForUnlogged.includes(key) && this._stamps[key]));
                        },

                        // Metodo incaricato di settare la proprietà reattiva `isBackToSession` a seconda che siano soddisfatte o meno tutte le condizioni preliminari al potenziale rientro in sessione
                        checkIfBackToSession()
                        {
                            if (!this._stamps.session)
                            {
                                this.isBackToSession = false;
                                return;
                            }
                            // Affinchè il `rientro in sessione` sia potenzialmente valido devono essere soddisfatte una serie di condizioni...
                            const { exitTime, tokenExpiresAt } = this._stamps.session;
                            // Condizione 1:    i timestamps `exitTime` e `tokenExpiresAt` devono essere numeri validi
                            if (isAValidNumber(exitTime) && isAValidNumber(tokenExpiresAt))
                            {
                                // Condizione 2:    il tempo trascorso fuori sessione deve essere accettabile per gli standard della piattaforma
                                if (useTimingStore().isAcceptableTimeGap(exitTime, false))
                                {
                                    // Condizione 3:    la vita residua del token non deve essere inferiore alla soglia per il relog automatico
                                    if ((tokenExpiresAt - Date.now()) >= constants.relogThreshold)
                                    {
                                        // La sessione è potenzialmente recuperabile.
                                        // La chiamata al backend all'endpoint `/api/app` decreterà l'effetivo rientro in sessione.
                                        // In caso di response favorevole, si ottengono anche i dati aggiornati da mostrare in `dashboard`
                                        // Laddove la pagina di rientro in sessione richieda dati extra questi verranno recuperati mediante un'ulteriore chiamata API
                                        console.log("POTENZIALE RIENTRO IN SESSIONE. SI ATTENDE CONFERMA DAL BACKEND");
                                        this.isBackToSession = true;
                                        return;
                                    }
                                }
                                else
                                {
                                    // Condizione 2 non soddisfatta.
                                    // Tempo fuori sessione eccessivo
                                    // Si setta comunque `isBackToSession TRUE` ma solo formalmente poichè si setta anche `excessiveExtraSessionTime TRUE`
                                    this.isBackToSession = true;
                                    this.excessiveExtraSessionTime = true;
                                    console.log("Permanenza fuori sessione eccessiva. L'utente viene sloggato.");
                                    return;
                                }
                            }
                            // Se anche solo una condizione preliminare non è stata soddisfatta si setta a false la proprietà reattiva `isBackToSession`
                            console.log("RIENTRO IN SESSIONE NON AUTORIZZATO");
                            this.isBackToSession = false;
                        },

                        // Metodo invocato in fase di inizializzazione della piattaforma.
                        // Questo metodo ha il compito di recuperare dal session storage tutti gli stamps previsti dal progetto (il `sessionStamp` ed altri stamps quali `loginStamp` e `registerStamp`) al fine di poter avviare la piattaforma sul corretto percorso iniziale di eventuale `rientro in sessione` o `rientro in pagine quali login o register recuperando gli eventuali dati pendenti`
                        // Dopo il recupero degli stamps, vengono invocati i metodi per il settaggio delle proprietà reattive che identificano il tipo di rientro
                        retrieveStamps(removeStampsFromStorage = true)
                        {
                            this._stamps = {};
                            [ ...this.returnLabelsForStamp(), "session" ].forEach( stamp => 
                                {
                                    this._stamps[stamp] = storage.sessionStorageGetItem(`${stamp}Stamp`);
                                    if (removeStampsFromStorage)
                                        storage.sessionStorageRemoveItem(`${stamp}Stamp`);
                                });
                            console.log(`Stamps recuperati dal session storage... ${JSON.stringify(this._stamps, null, 3)}`);
                            this.checkIfBackToUnlogged();
                            this.checkIfBackToSession();
                        },

                        returnStampObject(stamp)
                        {
                            if (this._stamps)
                                return this._stamps[stamp];
                            else
                                return null;
                        },

                        // Metodo incaricato di modificare la specificata proprietà reattiva dello store `stampsStore`
                        setStoreProperty(propertyName, propertyValue)
                        {
                            if (this.$state.hasOwnProperty(propertyName))
                                this[propertyName] = objDeepClone(propertyValue);
                        }
                    }
    });