export const getAllCookies = () =>
{
    const cookies = document.cookie;
    if (cookies === "")
        return {};
    const finalCookiesArray = cookies.split('; ').reduce((acc, currentCookie) => 
    {
        const [name, value] = currentCookie.split('=');
        acc[decodeURIComponent(name.trim())] = decodeURIComponent(value);
        return acc;
    }, {});
    return finalCookiesArray;
}

export const getCookie = (cookieName, cookiesObj) => (cookiesObj && cookiesObj[cookieName]) ? cookiesObj[cookieName] : null;

export const setCookie = (cookieName, cookieValue, cookieAttributes = {}) =>
{
    let cookieString = `${encodeURIComponent(cookieName)}=${encodeURIComponent(cookieValue)}`;
    if (cookieAttributes) 
    {
        for (const [key, value] of Object.entries(cookieAttributes)) 
        {
            if (value === true) 
                // Attributi che non richiedono un valore (esempio: HTTP-Only)
                cookieString += `; ${key}`;
            else
                // Attributi che richiedono un valore (esempio: Path)
                cookieString += `; ${key}=${value}`;
        }
    }
    document.cookie = cookieString;
};