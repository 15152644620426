import { defineStore } from "pinia";

import { computed, ref, reactive } from "vue";
import { parse, isValid } from "date-fns";

import { useApiStore } from "./apiStore";
import { useErrorHubStore } from "./errorHubStore";
import { useTimingStore } from "./timingStore";
import { useNavigationStore } from "./navigationStore";

import { objDeepClone } from "@/assets/utils/objectHandlers";
import { delayOnDatePickerActivation, showTimedApiResponseMsgIfSuccess } from "@/assets/configData/constants";

export const emptyValues = [ "", null, undefined ];
const mouseDownValidTargetClasses = [ ".floatingLabelInput", ".itemInput", ".itemLabel" ];
const isValidTarget = element => mouseDownValidTargetClasses.some( validTargetClass => element.matches(validTargetClass));

export const useInteractionsStore = defineStore(
    {
        id      :   "interactionsStore",
        state   :   () => 
                    ({
                        delegatedElement        :   null,
                        dynamicModels           :   {},
                        modelsConfigurations    :   reactive([]),
                        modelsReferences        :   reactive({}),
                        validationArray         :   [],
                        isDatePickerOn          :   false
                    }),
        actions :   {

                        handleEmptyClass(element)
                        {
                            (emptyValues.includes(element.value)) ? element.classList.add("empty") : element.classList.remove("empty");
                        },

                        unfocusElement(element)
                        {
                            element.classList.remove("itemFocused");
                            this.handleEmptyClass(element);
                        },

                        unfocusAll()
                        {
                            const elementsToUnfocus = this.delegatedElement.querySelectorAll(".itemFocused");
                            if (elementsToUnfocus.length !== 0)
                                elementsToUnfocus.forEach( toUnfocus => this.unfocusElement(toUnfocus));
                        },

                        handleMouseDownEvent(event)
                        {
                            console.log("MOUSEDOWN ON DELEGATED")
                            const element = event.target;
                            if (isValidTarget(element))
                            {
                                console.log("MouseDown event");
                                // Essendo l'evento `mousedown` associato alla `label` e non all'input, si individua il `parent` della label e poi si ricerca il `child` input e si estingue l'evento se è già `focused`
                                // Avendo superato la condizione di validità del target si può essere certi che l'"elementParent" esista.
                                const elementParent = element.closest(".floatingLabelInput");
                                let childElement = elementParent.querySelector(".itemInput");
                                if (childElement === this.modelsReferences["birthdate"])
                                {
                                    console.log("ATTIVAZIONE DATE PICKER")
                                    event.preventDefault();
                                    event.stopPropagation();
                                    this.switchToDatePicker();
                                    childElement.classList.add("itemFocused");
                                    childElement.focus();
                                }
                                else
                                {
                                    if (childElement.classList.contains("itemFocused"))
                                    {
                                        console.log("Click su evento in focus. Si annulla l'effetto del click.");
                                    }
                                    else
                                    {
                                        console.log("L'elemento 'input' cliccato non ha la classe .itemFocused, quindi la si fornisce, ma prima la si rimuove laddove presente in altri input");
                                        console.log("Inoltre si aggiunge o rimuove la classe `.empty` su ciascun elemento precedentemente in focus e sull'elemento corrente");
                                        this.unfocusAll();
                                        childElement.classList.add("itemFocused");
                                    }
                                    this.handleEmptyClass(childElement, "mousedown");
                                    event.preventDefault();
                                    event.stopPropagation();
                                    childElement.focus();
                                }
                            }
                            else
                                this.unfocusAll();
                        },
        
                        handleInputEvent(event)
                        {
                            this.handleEmptyClass(event.target);
                        },

                        switchToDatePicker()
                        {
                            this.unfocusAll();
                            this.stopEventDelegation();
                            setTimeout( () => this.switchDatePicker(), delayOnDatePickerActivation);
                            console.log("SWITCHED.............................")
                        },

                        switchFromDatePicker(clickCoordinates)
                        {
                            this.unfocusElement(this.modelsReferences["birthdate"]);
                            this.switchDatePicker(false);
                            this.startEventDelegation();
                            if (clickCoordinates)
                                setTimeout( () => 
                                    {
                                        const elementsInClickLocation = document.elementsFromPoint(clickCoordinates.x, clickCoordinates.y);
                                        if (elementsInClickLocation.length !== 0)
                                        {
                                            const targetForClick = elementsInClickLocation[0];
                                            const clickPropagationEvent = new MouseEvent("mousedown",
                                                {
                                                    bubbles :   true,
                                                    view    :   window,
                                                    clientX :   clickCoordinates.x,
                                                    clientY :   clickCoordinates.y     
                                                });
                                            targetForClick.dispatchEvent(clickPropagationEvent);
                                        }
                                    }, 10);
                            console.log("SWITCHED BACK.............................")
                        },

                        startEventDelegation()
                        {
                            if (this.delegatedElement)
                            {
                                this.delegatedElement.addEventListener("mousedown", this.handleMouseDownEvent);
                                this.delegatedElement.addEventListener("input", this.handleInputEvent);
                            }
                        },
        
                        setEventDelegation(target)
                        {
                            this.delegatedElement = target;
                            this.startEventDelegation();
                        },

                        stopEventDelegation()
                        {
                            if (this.delegatedElement)
                            {
                                this.delegatedElement.removeEventListener("mousedown", this.handleMouseDownEvent);
                                this.delegatedElement.removeEventListener("input", this.handleInputEvent);
                            }
                        },

                        resetEventDelegation()
                        {
                            this.stopEventDelegation();
                            this.delegatedElement = null;
                        },

                        initializeInteractions(interactionsArray)
                        {
                            this.resetInteractions();
                            const apiStore = useApiStore();
                            interactionsArray.forEach( obj =>
                                {
                                    if (obj.interaction)
                                    {
                                        this.modelsConfigurations.push(reactive(objDeepClone(obj)));
                                        const { modelName, modelDefault, interaction } = obj;
                                        if (interaction === "store")
                                        {
                                            this.dynamicModels[modelName] = computed(
                                                {
                                                    get :   () => apiStore.apiData[modelName],
                                                    set :   value => apiStore.setApiData({ [modelName] : value })
                                                });
                                        }
                                        else if (interaction === "locally")
                                            this.dynamicModels[modelName] = ref(modelDefault);
                                    }
                                });
                        },

                        retrieveInputReference(reference, modelName)
                        {
                            this.modelsReferences[modelName] = reference;
                            console.log("REFS: ", this.modelsReferences)
                        },

                        provideModelConfig(modelName)
                        {
                            return computed( () =>
                                {
                                    const config = this.modelsConfigurations.find(obj => (obj.modelName === modelName));
                                    if (config && (config.inputType === "depends"))
                                    {
                                        const dependency = this.dynamicModels[config.typeDependsBy];
                                        const actualInputType = config.typeValues.find( obj => (obj.dependencyValue === dependency)).inputType;
                                        return { ...config, inputType : actualInputType };
                                    }
                                    else
                                    {
                                        return config;
                                    }
                                });
                        },

                        validateModels(callerPage, requiresLoader = false)
                        {
                            if (requiresLoader)
                                useApiStore().processRequiringLoaderStarts();
                            this.validationArray = [];
                            this.modelsConfigurations.forEach( configObj =>
                                {
                                    const { modelName, toValidate, validationObj } = configObj;
                                    console.log(`Input corrente... ${modelName}`);
                                    if (toValidate)
                                    {
                                        console.log("E' richiesta validazione");
                                        const currentModelValue = this.dynamicModels[modelName];
                                        const { validationBy, validationErrors } = validationObj;
                                        const outcome = {};
                                        if ((currentModelValue === undefined) || (validationBy.missing.includes(currentModelValue)))
                                            outcome["error"] = validationErrors.onMissing;
                                        else 
                                        {
                                            console.log(`Il dato è presente e vale... ${currentModelValue}`);
                                            console.log(`Il tipo di dato è: ${typeof currentModelValue}`)
                                            if (validationBy.regex)
                                            {
                                                console.log(`Validazione a mezzo regex... ${validationBy.regex}`);
                                                // L'utilizzo del metodo `toString` applicato ad una variabile che dovrebbe già essere una stringa è una forma di precauzione laddove dati di tipo non stringa (esempio `phone` nel caso della pagina `register`) che abbiano già subìto una trasformazione di tipo non generino un errore
                                                const regex = new RegExp(validationBy.regex.toString());
                                                if (!regex.test(currentModelValue))
                                                    outcome["error"] = validationErrors.onWrong;
                                            }
                                            else if (validationBy.equalTo)
                                            {
                                                console.log(`Validazione a mezzo confronto con... ${validationBy.equalTo}`);
                                                console.log(`Confronto... ${currentModelValue} con ${this.dynamicModels[validationBy.equalTo]}`)
                                                if (currentModelValue !== this.dynamicModels[validationBy.equalTo])
                                                    outcome["error"] = validationErrors.onWrong;
                                            }
                                            else if (validationBy.parse)
                                            {
                                                console.log(`Validazione a mezzo parse con... ${validationBy.parse}`);
                                                if (!isValid(parse(currentModelValue ?? "", validationBy.parse, new Date())))
                                                    outcome["error"] = validationErrors.onWrong;
                                            }
                                        }
                                        this.validationArray.push({...outcome, "model" : modelName});
                                        console.log(`Validazione corrente... ${JSON.stringify(this.validationArray[this.validationArray.length - 1], null, 5)}`);
                                    }
                                });
                            if (this.anyValidationError)
                                useErrorHubStore().pushError(
                                    {
                                        from    :   "interactionsStore/validateModels",
                                        error   :   `Error on validating data entered by the user from the ${callerPage} page. Validation array is [ ${this.validationArray} ]`, 
                                    });
                            if (requiresLoader)
                                useApiStore().processRequiringLoaderEnds();
                        },

                        // Metodo da invocare solo a seguito di validazione con esito positivo
                        setActualDataType(requiresLoader = false)
                        {
                            if (requiresLoader)
                                useApiStore().processRequiringLoaderStarts();
                            this.modelsConfigurations.forEach( configObj =>
                                {
                                    const { modelName, toValidate, validationObj } = configObj;
                                    if (toValidate)
                                    {
                                        const { validationBy } = validationObj;
                                        const actualType = validationBy.dataType;
                                        switch (actualType)
                                        {
                                            case "number"   :   this.dynamicModels[modelName] = parseInt(this.dynamicModels[modelName]);
                                                                break;
                                        }
                                    }
                                });
                            if (requiresLoader)
                                useApiStore().processRequiringLoaderEnds();
                        },

                        returnValidationErrorKeys()
                        {
                            return this.validationArray.filter( obj => obj.error).map( ({error}) => error );
                        },

                        resetInteractions()
                        {
                            this.resetEventDelegation();
                            this.dynamicModels = {};
                            this.modelsConfigurations = [];
                            this.modelsReferences = {};
                            this.validationArray = [];
                        },

                        switchDatePicker(on = true)
                        {
                            this.isDatePickerOn = on;
                        },

                        handleActionClick(from, validationRequired = true)
                        {
                            let validationSuccess = true;
                            if (validationRequired)
                            {
                                this.validateModels(from, true);
                                if (this.anyValidationError)
                                {
                                    // Dati inseriti non validi.
                                    // Si comunica all'utente l'esito negativo della validazione e si resta nella pagina corrente
                                    console.log(`Dati inseriti non validi. Si resta in pagina [ ${from} ]`);
                                    useErrorHubStore().message(true, { type : "validation", indexes : this.returnValidationErrorKeys(), clickIsRequired : true });
                                    validationSuccess = false;
                                }
                                else
                                {
                                    // I dati inseriti sono validi.
                                    // Si effettua una trasformazione di tipo sui dati che richiedono di essere non stringa
                                    console.log("Eventuali trasformazioni di tipo");
                                    this.setActualDataType(true);
                                }
                            }
                            if (!validationRequired || validationSuccess)
                            {
                                // Se `from` vale "login" o "register" significa che la validazione ha avuto esito positivo
                                // Se `from` vale "subscription" non ha avuto luogo alcuna validazione
                                // Si procede con la chiamata API che finalizzi il click e conduca alla pagina seguente
                                console.log("Chiamata API di finalizzazione");
                                const apiCallLabel = (from === "subscription") ? "SUBSCRIBE" : from.toUpperCase();
                                const sensitive = {};
                                let setRelogTimer = false;
                                if (["login", "register"].includes(from))
                                {
                                    sensitive["password"] = this.dynamicModels.password;
                                    setRelogTimer = true;
                                }
                                useApiStore().apiUnrejectedCall(apiCallLabel, true, sensitive).then( res =>
                                    {
                                        let responseDataForErrorNotification = res.data;
                                        if (res.success)
                                        {
                                            // Operazione finalizzata con successo
                                            console.log("Operazione finalizzata con successo");
                                            if (setRelogTimer)
                                            {
                                                // Se `from` vale "login" o "register" si setta il timer per il relog automatico
                                                console.log("Si setta il timer per il relog automatico");
                                                useTimingStore().setTimerToAutoRelog();
                                            }
                                            if (showTimedApiResponseMsgIfSuccess)
                                            {
                                                // Se la piattaforma prevede la visualizzazione del messaggio di successo inviato dal backend lo si mostra (con modalità temporizzata)
                                                useErrorHubStore().message(true, { message : res.data.message }, true);
                                            }
                                            if (from === "register")
                                            {
                                                // Se `from` vale "register" si ha il redirect immediato alla pagina "subscription"
                                                console.log("Redirect su 'subscription'");
                                                useNavigationStore().redirectIfDifferentRoute("subscription");
                                                return;
                                            }
                                            else
                                            {
                                                // Se invece `from` vale "login" o "subscription" si ha il redirect su "dashboard", previa chiamata API all'endpoint "/api/app"
                                                useApiStore().apiUnrejectedCall("APP", true).then( appRes => 
                                                    {
                                                        if (appRes.success)
                                                        {
                                                            // Chiamata a "/api/app" effettuata con successo
                                                            console.log("Accesso alla pagina 'dashboard'");
                                                            useNavigationStore().redirectIfDifferentRoute("dashboard");
                                                            return;
                                                        }
                                                        else
                                                        {
                                                            // Chiamata a "/api/app" terminata con errore
                                                            console.log("Errore restituito dalla chiamata a '/api/app'");
                                                            responseDataForErrorNotification = appRes.data;
                                                            if (appRes.errorBy !== "server")
                                                            {
                                                                // Network error
                                                                console.log("Network error");
                                                                useErrorHubStore().setNetworkError();
                                                            }
                                                            else
                                                            {
                                                                // Errore restituito dal server
                                                                console.log("Errore restituito dal server");
                                                                useErrorHubStore().message(true, { message : appRes.data.message, clickIsRequired : true }, true);
                                                            }
                                                        }
                                                    });
                                            }
                                        }
                                        else
                                        {
                                            // Operazione non finalizzata
                                            console.log("Operazione non finalizzata");
                                            if (res.errorBy !== "server")
                                            {
                                                // Network error
                                                console.log("Network error");
                                                useErrorHubStore().setNetworkError();
                                            }
                                            else
                                            {
                                                // Errore restituito dal server
                                                console.log("Errore restituito dal server");
                                                useErrorHubStore().message(true, { message : res.data.message, clickIsRequired : true }, true);
                                            }
                                        }
                                        // Se una delle due chiamate API non ha avuto esito positivo, si esegue la chiamata API di notifica errore
                                        console.log("Se una delle due chiamate API non ha avuto esito positivo, si esegue la chiamata API di notifica errore")
                                        useApiStore().apiNotifyErrorCall(apiCallLabel, responseDataForErrorNotification);
                                    });
                            }
                        }
                    },
        getters :   {
                        anyValidationError : (state) => state.validationArray.some( obj => obj.error)
                    }             
    });