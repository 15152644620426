// Funzione di debounce finalizzata all'esecuzione `discreta` (non continua) di funzioni legate ad eventi
// `functionToExecute` è la funzione (da passare come arrow function) da eseguire in modalità `debounced`
// `timeToWait` è il tempo minimo (in millisecondi) dalla conclusione dell'event triggering all'esecuzione della funzione
// `immediateExecution` è un parametro booleano che... 
//      -   se settato a `true` consente l'esecuzione della funzione non appena ha luogo l'event triggering
//      -   se settato a `false` esegue la funzione rispettando il principio del `debouncing`, ovvero, funzione eseguita al termine dell'event triggering 
export function debounce(functionToExecute, timeToWait, immediateExecution) 
{
    // Creazione della variabile `timeout` con valore iniziale `undefined`
    let timeout;
    // Restituzione della funzione `debounced`
    return function() 
            {
                // Fare attenzione nel passaggio della funzione da sottoporre a `debounce`. Passarla sempre come arrow function per evitare problemi con il `this`
                const context = this;
                const args = arguments;
                // Definizione della funzione per il timeout
                const runLater = function() 
                                    {
                                        timeout = null;
                                        if (!immediateExecution) 
                                            functionToExecute.apply(context, args);
                                    };
                const callNow = immediateExecution && !timeout;
                clearTimeout(timeout);
                timeout = setTimeout(runLater, timeToWait);
                if (callNow) 
                    functionToExecute.apply(context, args);
            }
}
