import routerPages from "@/assets/static/yaml/config/routerPages.yml";
import { capitalizeFirstLetter } from "./generalUtils";
import { objDeepClone } from "./objectHandlers";

export const returnRouteData = (routerPage, dataType) =>
{
    const { pageName, pagePath, pageSuffix, pageFile, pageUpdate, mainMenu, menuItemIndex } = routerPage;
    let data = null;
    switch (dataType.toLowerCase())
    {
        case "path"     :   data = pagePath || `/${pageName}`;
                            break;
        case "name"     :   data = pageName;
                            break;
        case "title"    :   data = pageSuffix ?? capitalizeFirstLetter(pageName);
                            break;
        case "file"     :   data = pageFile || `Page${capitalizeFirstLetter(pageName)}`;
                            break;
        case "update"   :   data = pageUpdate ? objDeepClone(pageUpdate) : null;
                            break;
        case "mainmenu" :   data = mainMenu;
                            break;
        case "index"    :   data = menuItemIndex;
                            break;
    }
    if (dataType === "index")
    {
        console.log("INDEX IS: ", data)
        console.log("ROUTEPAGE IS...", routerPage)
    }
    return data;
};

export const returnRouteDataByAlias = (alias, dataType) =>
{
    const aliasLC = alias.toLowerCase();
    const routeObj = routerPages.find( ({ pageAliases, pageName }) => ((pageAliases && pageAliases.includes(aliasLC)) || (pageName === aliasLC)));
    return routeObj ? returnRouteData(routeObj, dataType) : null;
};

export const getProtectedRoutes = () => routerPages.filter( routerPage => routerPage.protected).map( ({ pageAliases, pageName }) => ({ pageAliases, pageName }));
