import { defineStore } from "pinia";
import { useI18n } from "vue-i18n";

import { useErrorHubStore } from "./errorHubStore";

import { i18n, loadLocale } from "@/i18n";
import * as storage from "@/assets/utils/storageHandlers";

export const useLocaleStore = defineStore(
    {
        id      :   "localeStore",
        state   :   () => 
                    ({
                        currentLocale               :   null, 
                        fallbackLocaleFileLoaded    :   false,
                        localeSettingsHandled       :   false
                    }),
        actions :   {
                        // In fase di inizializzazione non viene gestito il blocco `.catch` volutamente, perchè l'assenza della locale di fallback blocca tutto 
                        loadFallbackLocale()
                        {
                            const fallbackLocale = i18n.global.fallbackLocale.value;
                            loadLocale(fallbackLocale, true).then( () => 
                                {
                                    this.fallbackLocaleFileLoaded = true;
                                    this.currentLocale = fallbackLocale;
                                });
                        },

                        async applyLocaleSettings()
                        {
                            const locale = storage.localStorageGetItem("locale");
                            if (!locale)
                            {
                                this.localeSettingsHandled = true;
                                return;
                            }
                            if (typeof locale === "string")
                            {
                                try
                                {
                                    await loadLocale(locale);
                                    console.log(`Caricata la locale ${locale} come da preferenze salvate nel local storage`);
                                    this.currentLocale = locale;
                                    this.localeSettingsHandled = true;
                                    return;
                                }
                                catch(error)
                                {
                                    console.log(`Caricamento della locale ${locale} non riuscito. La preferenza verrà rimossa dal local storage.`);
                                }
                            }
                            else
                                console.log(`Dato non conforme [ locale = ${locale} ]. Verrà rimosso dal local storage.`);
                            storage.localStorageRemoveItem("locale"); 
                            useErrorHubStore().pushError(
                                {
                                    from    :   "localeStore/applyLocaleSettings",
                                    error   :   `Error on loading the preference [ locale = ${locale} ].`
                                });
                            this.localeSettingsHandled = true;
                        },

                        returnLocaleString(localeTextKey)
                        {
                            const { t } = useI18n();
                            const errorHubStore = useErrorHubStore();
                            let stringToReturn = "";
                            if ([ "critical", "device" ].includes(localeTextKey))
                                stringToReturn = t(`errors.${localeTextKey}[${errorHubStore.messageObj.indexes[0]}]`);
                            else if (localeTextKey === "short")
                                stringToReturn = t(`message[${errorHubStore.messageObj.indexes[0]}]`);
                            else if (localeTextKey === "validation")
                            {
                                stringToReturn = t("message[0]") + "<br/>";
                                errorHubStore.messageObj.indexes.forEach( localeErrorKey => stringToReturn = stringToReturn.concat("<br> - ", t(`errors.validation.${localeErrorKey}`)));
                            }
                            return stringToReturn;
                        }
                    }
    });