import { defineStore } from "pinia";
import { watch } from "vue";

import { useErrorHubStore } from "./errorHubStore";
import { useApiStore } from "./apiStore";

import * as constants from "@/assets/configData/constants";
import { debounce } from "@/assets/utils/debounce";

export const useTimingStore = defineStore(
    {
        id      :   "timingStore",
        state   :   () =>
                    ({
                        autoRelogNotifier   :   null,
                        exitWaitingRoom     :   false,
                    }),
        actions :   {
                        isAcceptableTimeGap(timeZero, checkInactivityTime = true)
                        {
                            const now = Date.now();
                            const maxGap = (checkInactivityTime) ? constants.maxInactivityTime : constants.maxExtraSessionTime;
                            const gap = now - timeZero;
                            console.log(`${checkInactivityTime ? "INACTIVITY" : "EXTRA SESSION"} TIME: ${gap}`);
                            return gap <= maxGap;
                        },

                        resetAutoUpdateApiCallTimer()
                        {
                            if (this._autoUpdateTimer)
                                this._autoUpdateTimer = null;
                        },

                        // Le chiamate di update non prevedono l'invio di dati sensibili
                        setAutoUpdateApiCallTimer(updateObj)
                        {
                            this.resetAutoUpdateApiCallTimer();
                            this._autoUpdateTimer = setInterval( () => 
                                {
                                    // LA SEGUENTE CONDIZIONE E' RIDONDANTE. LA SI LASCIA IN ATTESA DI DECIDERE SE "SLOGGARE" L'UTENTE A SEGUITO DI RESPONSE NEGATIVA (COME TOKEN NON VALIDO) O MENO
                                    if (!updateObj.onlyIfLoggedIn || useApiStore().isUserLoggedIn)
                                    {
                                        this._lastAutoUpdateCall = 
                                        {
                                            label       :   updateObj.endpointLabel,
                                            callTime    :   Date.now(),
                                            status      :   "pending" 
                                        };
                                        console.log(`UPDATE CALL INCOMING FOR ${updateObj.endpointLabel} - NOW: ${Date.now()}`);
                                        useApiStore().apiUnrejectedCall(updateObj.endpointLabel).then( res =>
                                            {
                                                if (res.success)
                                                {
                                                    this._lastAutoUpdateCall = 
                                                    { 
                                                        ...this._lastAutoUpdateCall, 
                                                        responseTime    :   Date.now(),
                                                        status          :   "success"  
                                                    };
                                                }
                                                else
                                                {
                                                    this._lastAutoUpdateCall = 
                                                    { 
                                                        ...this._lastAutoUpdateCall, 
                                                        responseTime    :   Date.now(),
                                                        status          :   "error",
                                                        errorBy         :   res.errorBy   
                                                    };
                                                    useApiStore().apiNotifyErrorCall(updateObj.endpointLabel, res.data);
                                                    if (res.errorBy !== "server")
                                                    {
                                                        // Valutare come comportarsi nel caso di errore di rete
                                                    }
                                                    // Valutare se sia il caso di mantenere in essere il setInterval anche in caso di errore nella response
                                                }
                                            });
    
                                    }
                                }, updateObj.updateTimer);
                        },

                        waitingRoomOn()
                        {
                            console.log("SETTING THE EXIT COUNTER")
                            setTimeout( () => this.exitWaitingRoom = true, constants.minWaitingRoomTime);
                        },

                        // Metodo che aggiorna il timestamp relativo all'ultimo evento indicante attività da parte dell'utente
                        updateLastActivityTime()
                        {
                            // Sottraendo al `Date.now()` il tempo di debounce si ha la certezza di salvare l'effettivo momento di ultima attività dell'utente
                            this._lastActivityTime = Date.now() - constants.timerForDebouncedUserEventListener;
                            console.log("LAST ACTIVITY: ", this._lastActivityTime)
                        },

                        // Funzione di debouce sull'aggiornamento dell'ultimo evento
                        debouncedUserEventListening : debounce( function() { this.updateLastActivityTime(); }, constants.timerForDebouncedUserEventListener, false),

                        // Metodo di attivazione degli event listeners
                        inactivityTimerOn()
                        {
                            this.inactivityTimerOff();
                            // Trattandosi di inizializzazione e non di `update`, in questo caso non si sottrae il tempo di debounce a `Date.now()`
                            this._lastActivityTime = Date.now();
                            // Si settano gli event listeners (debounced) sui singoli eventi identificativi di attività da parte dell'utente
                            constants.eventsToCheck.forEach( eventToCheck => window.addEventListener(eventToCheck, this.debouncedUserEventListening));
                            console.log("INACTIVITY TIMER ON")
                        },

                        // Metodo di disattivazione degli event listeners
                        inactivityTimerOff()
                        {
                            // Se il timer risulta essere settato lo si resetta e si rimuovono gli event listeners
                            if (this._lastActivityTime)
                            {
                                constants.eventsToCheck.forEach( eventToCheck => window.removeEventListener(eventToCheck, this.debouncedUserEventListening));
                                this._lastActivityTime = null;
                                console.log("INACTIVITY TIMER OFF")
                            }
                        },

                        // Metodo incaricato di resettare il timer per il relog automatico
                        resetTimerToAutoRelog()
                        {
                            if (this._relogTimer)
                            {
                                clearTimeout(this._relogTimer);
                                this._relogTimer = null;
                                console.log("AUTO RELOG TIMER RESET")
                            }
                        },

                        // Metodo incaricato di settare il timer per il relog automatico
                        // Il parametro `tokenExpiresAt` viene passato dal blocco chiamante, quindi ha le seguenti caratteristiche:
                        // - non necessita di controlli sulla validità, poichè già eseguiti dal blocco chiamante
                        // La funzione restituisce un valore booleano, avente le seguenti implicazioni:
                        // - `true` significa che il timer è stato correttamente settato
                        // - `false` significa che il timer non è stato settato ed, anzi, è stato resettato, laddove già in essere.
                        // La funzione restituisce `false` solo nel caso in cui il timer sia risultato di valore negativo, ovvero il dato di scadenza token, inviato dal server, sia, per qualche ragione errato.
                        setTimerToAutoRelog(tokenExpiresAt)
                        {
                            console.log("SETTING RELOG TIMER")
                            this.resetTimerToAutoRelog();
                            const relogTime = tokenExpiresAt - (Date.now() + constants.relogThreshold);
                            console.log("RELOG TIME: ", Math.floor(relogTime/1000), " secondi")
                            if (relogTime >= 0)
                            {
                                this._relogTimer = setTimeout( () => 
                                    {
                                        // Funzione da eseguire nel momento in cui deve aver luogo in relog automatico
                                        if (this.isAcceptableTimeGap(this._lastActivityTime))
                                        {
                                            // Se l'eventuale tempo di inattività risulta accettabile, si da seguito alla notifica di `richiesta esecuzione relog`, dopo aver resettato il contatore del tempo di inattività ed i relativi listeners
                                            this.inactivityTimerOff();
                                            console.log("BEFORE NOTIFIER TRUE")
                                            this.autoRelogNotifier = true;
                                            console.log("AFTER NOTIFIER TRUE")
                                        }
                                        else
                                        {
                                            // Si memorizza in errorHubStore la condizione di token scaduto
                                            useErrorHubStore().pushError(
                                                {
                                                    from    :   "timingStore/setTimerToAutoRelog",
                                                    error   :   `Automatic relog denied for long inactivity period [ ${this._lastActivityTime} ]`
                                                });
                                            // In caso contrario, il contatore ed i listeners vengono comunque resettati ma non viene notificata la richiesta di relog
                                            this.inactivityTimerOff();
                                            // VALUTARE SE NOTIFICARE IN QUALCHE MANIERA IL MANCATO RELOG E MAGARI SETTARE UN TIMER CHE ANNULLI TOKENEXPIRESAT IN APISTORE
                                        }
                                    }, relogTime);
                                console.log("AUTO RELOG TIMER SET")
                                return true;
                            }
                            else
                            {
                                useErrorHubStore().pushError(
                                    {
                                        from    :   "timingStore/setTimerToAutoRelog",
                                        error   :   `Wrong value for relogTime: [ ${relogTime} ]. Wrong value for token expiration time: [ ${tokenExpiresAt} ].`
                                    });
                                return false;
                            }
                        },

                        setOperationsForAutoRelog()
                        {
                            console.log("**************************************************************************************************************************")
                            console.log("SETTING OPERATIONS FOR RELOG")
                            this.resetNotifier("autoRelogNotifier");
                            this.inactivityTimerOn();
                            if (!(this.setTimerToAutoRelog(useApiStore().tokenExpiresAt)))
                            {
                                this.inactivityTimerOff();
                                throw new Error("Impossibile settare il timer per il relog automatico");
                            }
                        },

                        // Metodo invocato per resettare uno specifico `notificatore`
                        resetNotifier(notifierName)
                        {
                            if (this.$state.hasOwnProperty(notifierName))
                                this[notifierName] = null;
                        },

                        // Metodo invocato in preparazione all'unload
                        prepareToUnload()
                        {
                            console.log("PREPARE TO UNLOAD TIMING STORE")
                            // Si resettano tutti i timer e gli eventuali event listeners
                            this.resetAutoUpdateApiCallTimer();
                            this.resetTimerToAutoRelog();
                            this.inactivityTimerOff();
                        }
                    } 
    });