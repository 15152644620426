import "./assets/style/scss/main.scss";
import { createApp } from "vue";
import { createPinia } from "pinia";
import { i18n } from "./i18n";
import { router } from "./router";
import App from "./App.vue";

const app = createApp(App);
const pinia = createPinia();

app
.use(pinia)
.use(i18n)
.use(router)
.mount("#app");