import { defineStore } from "pinia";
import { watch } from "vue";

import { useErrorHubStore } from "./errorHubStore";

import { checkDeviceHistory, timerForDebouncedResizeListener, tabletMaxWidth, desktopHDMinWidth } from "@/assets/configData/constants";
import * as deviceUtils from "@/assets/utils/deviceCheckUtils";
import { capitalizeFirstLetter } from "@/assets/utils/generalUtils";
import { debounce } from "@/assets/utils/debounce";
import deviceCriteria from "@/assets/static/yaml/config/deviceCriteria.yml";

export const useDeviceStore = defineStore(
    {
        id      :   "deviceStore",
        state   :   () =>
                    ({
                        isValidDevice   :   null,
                        deviceWidth     :   window.innerWidth
                    }),
        actions :   {
                        // Metodo utilizzato per settare (se `checkDeviceHistory` true) il watcher (autoresettante) che monitora la validità del device in uso
                        setHistoryWatcher()
                        {
                            if (checkDeviceHistory)
                            {
                                console.log("DEVICE WATCHER ACTIVE")
                                // Watcher con handler non reattivo
                                this._deviceWatcherStopper = watch( ()=> 
                                    this.isValidDevice, newValue =>
                                        {
                                            console.log("DEVICE WATCHER INVOKED")
                                            if (newValue)
                                            {
                                                console.log("DEVICE IS GOOD BY WATCHER")
                                                // Al primo verificarsi della condizione `true` per la proprietà osservata, si setta a `true` la proprietà non reattiva `_validDeviceInHistory` e si rimuove il watcher
                                                this._validDeviceInHistory = true;
                                                this._deviceWatcherStopper();
                                                this._deviceWatcherStopper = null;
                                                console.log("DEVICE WATCHER STOPPED")
                                            }
                                        });
                            }
                        },

                        // Metodo atto a verificare se il device in uso è compatibile con i requisiti di piattaforma
                        checkDevice()
                        {
                            console.log("WIDTH: ", window.innerWidth)
                            console.log("HEIGHT: ", window.innerHeight)
                            console.log("CHECKING DEVICE")
                            this.deviceWidth = window.innerWidth;
                            // Per ogni singolo criterio di validità...
                            for (const criterionObj of deviceCriteria)
                            {
                                // se il criterio corrente richiede di essere verificato...
                                if (criterionObj.toBeChecked)
                                    // si destrutturano le proprietà di ciascun controllo entro il criterio corrente
                                    for (const { value, method, label } of criterionObj.checks)
                                    {
                                        // e si verifica che il device sia valido per il criterio/controllo corrente.
                                        const validForCurrentCriterion = deviceUtils[method](value);
                                        // Se il device risulta essere non valido per il criterio/controllo corrente non è valido in senso generale
                                        if (!validForCurrentCriterion)
                                        {
                                            console.log("DEVICE NOT GOOD")
                                            this.isValidDevice = false;
                                            useErrorHubStore().pushError(
                                            {   
                                                from    :   "deviceStore/checkDevice",
                                                error   :   `Error on device criterion [ ${criterionObj.criterion}-${label} ]`
                                            });
                                            return;
                                        }
                                    }
                            }
                            // Se il device è risultato valido per tutti i criteri/controlli allora è valido in sè
                            console.log("DEVICE IS GOOD")
                            this.isValidDevice = true;
                        },

                        debouncedResizeListening : debounce( function() { this.checkDevice(); }, timerForDebouncedResizeListener, false),

                        // Metodo inizializzatore delle procedure di check e monitoraggio dei parametri del device
                        initDeviceCheck()
                        {
                            console.log("INITIALIZING DEVICE")
                            this.setHistoryWatcher();
                            this.checkDevice();
                            window.addEventListener("resize", this.debouncedResizeListening);
                        },

                        // Metodo incaricato di resettare tutti i meccanismi di monitoraggio dei parametri del device
                        prepareToUnload()
                        {
                            console.log("PREPARE TO UNLOAD DEVICE STORE")
                            window.removeEventListener("resize", this.debouncedResizeListening);
                            if (this._deviceWatcherStopper)
                            {
                                this._deviceWatcherStopper();
                                this._deviceWatcherStopper = null;
                            }
                        },

                        // Metodo che restituisce il valore della proprietà non reattiva `_validDeviceInHistory` che testimonia se il device è risultato valido almeno una volta
                        validAtLeastOnce()
                        {
                            return this._validDeviceInHistory;
                        }
                    },
        getters :   {
                        isDeviceTablet          :   (state) =>  state.isValidDevice && state.deviceWidth <= tabletMaxWidth,
                        isDeviceDesktopStd      :   (state) =>  state.isValidDevice && !(state.isDeviceTablet || state.isDeviceDesktopHD),
                        isDeviceDesktopHD       :   (state) =>  state.isValidDevice && state.deviceWidth >= desktopHDMinWidth,
                        returnDeviceString      :   (state) =>  state.isDeviceTablet ? "tablet" : (state.isValidDevice ? "desktop" : null),
                        returnDeviceStringCFL   :   (state) =>  capitalizeFirstLetter(state.returnDeviceString)
                    }
    });