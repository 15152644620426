import { defineStore } from "pinia";
import { reactive, watch } from "vue";

import { useMainStore } from "./mainStore";
import { useStampsStore } from "./stampsStore";
import { useApiStore } from "./apiStore";
import { useErrorHubStore } from "./errorHubStore";

import { getProtectedRoutes, returnRouteDataByAlias } from "@/assets/utils/navigationUtils";

export const useNavigationStore = defineStore(
    {
        id      :   "navigationStore",
        state   :   () => 
                    ({
                        initialized     :   false,
                        route           :   reactive(null),
                        router          :   null,
                        activeMenuItem  :   null
                    }),
        actions :   {
                        setWatcherOnRoute()
                        {
                            this._watcherOnRouteStopper = watch( () =>
                                this.route.name, newValue =>
                                    {
                                        if (newValue)
                                        {
                                            console.log("ROUTE IS CHANGED TO... ", newValue)
                                            this.activeMenuItem = returnRouteDataByAlias(newValue, "index") ?? null;
                                            console.log("THE VALUE OF ACTIVEMENUITEM IS.... ", this.activeMenuItem)
                                        }
                                    });
                        },

                        resetWatcherOnRoute()
                        {
                            if (this._watcherOnRouteStopper)
                            {
                                this._watcherOnRouteStopper();
                                this._watcherOnRouteStopper = null;
                            }
                        },

                        initNavigationStore(routeInstance, routerInstance)
                        {
                            this.route = reactive(routeInstance);
                            console.log("ROUTE IS INITIALIZED TO... ", this.route.name)
                            this.activeMenuItem = returnRouteDataByAlias(this.route.name, "index") ?? null;
                            console.log("THE VALUE OF ACTIVEMENUITEM IS.... ", this.activeMenuItem)
                            this.setWatcherOnRoute();
                            this.router = routerInstance;
                            this._protectedRoutes = getProtectedRoutes();
                            this._requestedRoute = { name : this.route.name, path : this.route.path };
                            this.initialized = true;
                            console.log("NAVIGATION STORE IS INITIALIZED")
                        },

                        redirectIfDifferentRoute(newRoute, checkBy = "name")
                        {
                            if (this.route[checkBy] !== newRoute)
                                this.router.replace({ [checkBy] : newRoute });
                        },

                        setWatcherOnWaitingRoomOut()
                        {
                            // Settaggio del watcher per l'attesa in waiting room nel caso di presenza del cookie per logout differito.
                            console.log("Settaggio del watcher per l'attesa in waiting room nel caso di presenza del cookie per logout differito.");
                            this._watcherOnWaitingRoomOutStopper = watch( () =>
                                useMainStore().keepWaiting, newValue =>
                                    {
                                        if (!newValue)
                                        {
                                            // Attesa in waiting room conclusa
                                            console.log("Presenza del cookie per logout differito e pagina non protetta richiesta - all'uscita da waiting room.");
                                            this.router.replace({ name : this._requestedRoute.name });
                                            // Rimozione del watcher
                                            this._watcherOnWaitingRoomOutStopper();
                                            this._watcherOnWaitingRoomOutStopper = null;
                                            console.log("Watcher 'setWatcherOnWaitingRoomOut' rimosso");
                                        }
                                    });
                        },

                        manageRestrictedNavigation()
                        {
                            // Tempo extra session eccessivo oppure logout pendente.
                            if ((this._requestedRoute.name === "root") || (this._protectedRoutes.some( ({ pageName }) => (pageName === this._requestedRoute.name))))
                            {
                                // Se il redirect avviene su richiesta di rotta protetta o "root", l'utente viene informato del logout forzato mediante messaggio
                                console.log("Redirect su login per extra session time eccessivo o logout pendente.");
                                this.router.replace({ name : "login" });
                                if (this._protectedRoutes.some( ({ pageName }) => (pageName === this._requestedRoute.name)))
                                {
                                    const indexes = useStampsStore().excessiveExtraSessionTime ? [ 2 ] : [ 1 ];
                                    // Se è stata richiesta una rotta protetta si mostra un messaggio che giustifichi il redirect su "login"
                                    useErrorHubStore().message(true, { type : "short", indexes : indexes, "clickIsRequired" : true });
                                    console.log("MESSAGGIO ALL'UTENTE PER EXTRA SESSION TIME O LOGOUT PENDENTE");
                                }                            
                            }
                            else
                            {
                                console.log("L'utente accede alla pagina non protetta richiesta.");
                                if (this.route.name === "root")
                                {
                                    // Blocco di codice necessario per gestire il caso in cui, in presenza del cookie per il logout differito e richiesta di rotta non protetta, la piattaforma rischia di restare impantanata nella `waiting room`
                                    if (!useMainStore().keepWaiting)
                                    {
                                        console.log("Presenza del cookie per logout differito e pagina non protetta richiesta.");
                                        this.router.replace({ name : this._requestedRoute.name });
                                    }
                                    else
                                    {
                                        // Settaggio del watcher 
                                        this.setWatcherOnWaitingRoomOut();
                                    }
                                }
                            }
                        },

                        managePlatformAccess(isKeepWaiting)
                        {
                            // Piattaforma non ancora inizializzata
                            let newRouteName = this._requestedRoute.name;
                            console.log("PIATTAFORMA NON ANCORA INIZIALIZZATA");
                            if (isKeepWaiting)
                            {
                                // Avendo `isKeepWaiting TRUE` si è certi che si sta avviando una nuova sessione non essendoci stamps validi nel session storage.
                                // Si deve accedere alla "root" per essere in "waiting room"
                                console.log("La waiting room è in ROOT");
                                newRouteName = "root";
                            }
                            else
                            {
                                // Non essendo la piattaforma ancora inizializzata ed essendo `isKeepWaiting FALSE`, devono per forza essere presenti degli stamps
                                // Questo implica che siamo di fronte ad un potenziale rientro `in sessione` o `unlogged` 
                                // Ne consegue che:
                                // A ---> sessionStamp è presente ed ha priorità sugli altri stamps
                                //  A1 ---> se è stata richiesta "root" si ha il redirect su "dashboard"
                                //  A2 ---> altrimenti si accede alla rotta richiesta
                                // B ---> sessionStamp è assente, quindi c'è per forza uno tra loginStamp e registerStamp
                                //  B1 ---> se è stata richiesta "root" oppure una rotta protetta si ha il redirect sulla rotta non protetta specifica dello stamp
                                //  B2 ---> altrimenti si accede alla rotta richiesta, senza curarsi dello stamp

                                if (useStampsStore().isBackToSession) /* A */
                                {
                                    // sessionStamp presente
                                    console.log("sessionStamp presente");
                                    if (this._requestedRoute.name === "root") /* A1 */
                                    {
                                        // Redirect da "root" a "dashboard"
                                        console.log("Redirect su dashboard");
                                        newRouteName = "dashboard";
                                    }
                                    else /* A2 */
                                    {
                                        // Accesso alla rotta richiesta
                                        console.log(`Accesso alla rotta ${this._requestedRoute.name} come richiesto`);
                                    }
                                }
                                else /* B */
                                {
                                    // sessionStamp assente
                                    console.log("sessionStamp assente");
                                    if  ((this._requestedRoute.name === "root") 
                                        || 
                                        (this._protectedRoutes.some( ({ pageName }) => (pageName === this._requestedRoute.name)))) /* B1 */
                                    {
                                        // Redirect sulla rotta non protetta identificata dallo stamp
                                        console.log("Redirect sulla rotta non protetta identificata dallo stamp.");
                                        // Al momento, in virtù del fatto che ci sono solo due rotte non protette, "login" e "register" si accede all'una escludendo direttamente l'altra. Implementare un check più articolato se si aggiungono altre rotte non protette e recanti stamps.
                                        newRouteName = useStampsStore().checkIfUnloggedStamp("register") ? "register" : "login";
                                    }
                                    else /* B2 */
                                    {
                                        // Si accede alla pagina non protetta richiesta, a prescindere dallo stamp
                                        console.log("L'utente accede alla pagina non protetta richiesta.");
                                    }
                                }
                            }
                            this.redirectIfDifferentRoute(newRouteName);
                        },

                        managePlatformLanding()
                        {
                            let newRouteName = this.route.name;
                            let eventMessage = null;
                            // Attesa ultimata e accesso alla rotta finale.
                            // Situazioni possibili:
                            // C ---> La risposta del server alla chiamata su `api/app` è di successo. Il token è valido e ci sono i dati `dashboard` aggiornati.
                            //  C1 ---> L'avvio è avvenuto in modalità `nuova sessione` (nessuno stamp nel session storage)...
                            //   C1/1 ---> Se la rotta cercata era una rotta protetta vi si accede
                            //   C1/2 ---> Se la rotta cercata era una rotta non protetta si ha il redirect su `dashboard`
                            //  C2 ---> La presenza degli stamps implica un rientro potenziale
                            //   C2/1 ---> E' presente il sessionStamp (prioritario)
                            //    C2/1/1 ---> Se la rotta richiesta è protetta ci è già.
                            //    C2/1/2 ---> Altrimenti si resta/va in dashboard
                            //   C2/2 ---> Non è presente il sessionStamp ma sicuramente uno stamp di `unlogged`
                            //    C2/2/1 ---> Se la rotta richiesta è protetta ci va
                            //    C2/2/2 ---> Altrimenti redirect su dashboard
                            // D ---> La risposta del server è negativa. Il token non è valido e l'utente è automaticamente sloggato (senza forzature dal frontend)
                            //  D1 ---> Se in modalità "nuova sessione"...
                            //   D1/1 ---> Se la rotta richiesta è protetta oppure "root" si ha un redirect su "login"
                            //   D1/2 ---> Altrimenti si accede alla rotta richiesta
                            //  D2 ---> Presenza degli stamps...
                            //   D2/1 ---> E' presente il sessionStamp (prioritario)
                            //    D2/1/1 ---> Se rotta protetta o "root" redirect su "login" con messaggio
                            //    D2/1/2 ---> Altrimenti resta dov'è
                            //   D2/2 ---> Non è presente il sessionStamp ma sicuramente uno stamp di `unlogged`
                            //    D2/2/1 ---> Se rotta protetta resta dov'è ma con messaggio.
                            //    D2/2/2 ---> Altrimenti resta dov'è ma senza messaggio
                            // E ---> Errore di rete.

                            if (useApiStore().isUserLoggedIn) /* C */
                            {
                                // Response positiva, utente loggato e dati aggiornati per "dashboard"
                                if (!useMainStore().anyValidStamp) /* C1 */
                                {
                                    // Nessuno stamp, accesso in modalità "nuova sessione"
                                    if (this._protectedRoutes.some( ({ pageName }) => (pageName === this._requestedRoute.name))) /* C1/1 */
                                    {
                                        // Si può accedere alla rotta cercata poichè protetta e utente loggato
                                        console.log(`Si accede alla rotta protetta cercata [ ${this._requestedRoute.name} ] poichè si è loggati.`);
                                        newRouteName = this._requestedRoute.name;
                                    }
                                    else /* C1/2 */
                                    {
                                        // Si ha un redirect su "dashboard"
                                        console.log("Redirect su 'dashboard'.");
                                        newRouteName = "dashboard";
                                    }
                                }
                                else /* C2 */
                                {
                                    // Presenza di stamps - Modalità `rientro`
                                    if (useStampsStore().isBackToSession) /* C2/1 */
                                    {
                                        // Presente il sessionStamp
                                        if (this._protectedRoutes.some( ({ pageName }) => (pageName === this._requestedRoute.name))) /* C2/1/1 */
                                        {
                                        // Resta nella rotta protetta in cui è già
                                            console.log(`Lo user è già nella rotta protetta richiesta [ ${this._requestedRoute.name} ]`);
                                            newRouteName = this._requestedRoute.name;
                                        }
                                        else /* C2/1/2 */
                                        {
                                            // La rotta finale è dashboard
                                            console.log("La rotta finale è dashboard");
                                            newRouteName = "dashboard";
                                        }
                                    }
                                    else /* C2/2 */
                                    {
                                    // sessionStamp assente
                                    if (this._protectedRoutes.some( ({ pageName }) => (pageName === this._requestedRoute.name))) /* C2/2/1 */
                                        {
                                            // Si accede alla rotta protetta richiesta
                                            console.log(`Si accede alla rotta protetta richiesta ${this._requestedRoute.name}`);
                                            newRouteName = this._requestedRoute.name;
                                        }
                                        else /* C2/2/2 */
                                        {
                                            // Redirect su dashboard
                                            console.log("Redirect su dashboard");
                                            newRouteName = "dashboard";
                                        }
                                    }
                                }
                            }
                            else if (!useErrorHubStore().networkError) /* D */
                            {
                                // Response negativa dal server. Utente non loggato, senza forzature lato frontend
                                if (!useMainStore().anyValidStamp) /* D1 */
                                {
                                    // Nessuno stamp, accesso in modalità "nuova sessione"
                                    if  ((this._protectedRoutes.some( ({ pageName }) => (pageName === this._requestedRoute.name))) 
                                        || 
                                        (this._requestedRoute.name === "root")) /* D1/1 */
                                    {
                                        // Redirect su login
                                        console.log("Rotta finale: login");
                                        newRouteName = "login";
                                        if (this._requestedRoute.name !== "root")
                                        {
                                            // Se è stata richiesta una rotta protetta si mostra un messaggio che giustifichi il redirect su "login"
                                            eventMessage = { type : "short", indexes : [ 1 ], "clickIsRequired" : true };
                                            console.log("MESSAGGIO ALL'UTENTE");
                                        }
                                    }
                                    else /* D1/2 */
                                    {
                                        // Si accede alla rotta richiesta
                                        console.log(`Accesso alla rotta richiesta [ ${this._requestedRoute.name} ]`);
                                        newRouteName = this._requestedRoute.name;
                                    }
                                }
                                else /* D2 */
                                {
                                    // Stamps presenti
                                    if (useStampsStore().isBackToSession) /* D2/1 */
                                    {
                                        // Session stamp presente
                                        if  ((this._protectedRoutes.some( ({ pageName }) => (pageName === this._requestedRoute.name))) 
                                            || 
                                            (this._requestedRoute.name === "root"))/* D2/1/1 */
                                        {
                                            // Redirect su login
                                            console.log("Redirect su login");
                                            newRouteName = "login";
                                            if (this._requestedRoute.name !== "root")
                                            {
                                                // Se è stata richiesta una rotta protetta si mostra un messaggio che giustifichi il redirect su "login"
                                                eventMessage = { type : "short", indexes : [ 1 ], "clickIsRequired" : true };
                                                console.log("MESSAGGIO ALL'UTENTE");
                                            }
                                        }
                                        else /* D2/1/2 */
                                        {
                                            // Resta dove è
                                            console.log("Resta dove è");
                                        }
                                    }
                                    else /* D2/2 - D2/2/1 - D2/2/2 */
                                    {
                                        // Unlogged stamp
                                        // Resta nella rotta in cui già è
                                        // SETTARE IL MESSAGGIO SOLO SE LA ROTTA RICHIESTA ERA UNA ROTTA PROTETTA
                                        console.log("Resta dove è");
                                        if  (this._protectedRoutes.some( ({ pageName }) => (pageName === this._requestedRoute.name))) /* D2/2/1 */
                                        {
                                            // Se è stata richiesta una rotta protetta si mostra un messaggio che giustifichi il redirect su "login"
                                            eventMessage = { type : "short", indexes : [ 1 ], "clickIsRequired" : true };
                                            console.log("MESSAGGIO ALL'UTENTE");
                                        }
                                    }
                                }
                            }
                            else /* E */
                            {
                                // Errore di rete.
                                // Resta dove è
                                // PER ALCUNE CASISTICHE BISOGNA SALVARE UNO SPECIFICO STAMP
                                console.log("Network error. Si resta nella pagina in cui si è già");
                                if (useStampsStore().isBackToSession)
                                {
                                    // Salvataggio del session stamp recuperabile mediante refresh
                                    console.log("Salvataggio del session stamp rinvenibile al refresh");
                                    useStampsStore().saveStamp("session", false);
                                }
                                else if (useMainStore().anyValidStamp)
                                {
                                    // E' presente uno stamp di `unlogged`; lo si salva per poterlo recuperare mediante refresh (a prescindere dalla pagina corrente)
                                    console.log("E' presente uno stamp di `unlogged`; lo si salva per poterlo recuperare mediante refresh (a prescindere dalla pagina corrente)");
                                    let unloggedStamp = "";
                                    if (useStampsStore().checkIfUnloggedStamp("register"))
                                        unloggedStamp = "register";
                                    else
                                        unloggedStamp = "login";
                                    console.log(`Lo stamp salvato è ${unloggedStamp}Stamp`);
                                    useStampsStore().saveStamp(unloggedStamp, false);
                                }
                            }
                            this.redirectIfDifferentRoute(newRouteName);
                            if (eventMessage)
                                useErrorHubStore().message(true, eventMessage);
                        }
                    },
        getters :   {
                        pageHasMainMenu     :   (state) => returnRouteDataByAlias(state.route.name, "mainMenu") || false
                    } 
    });