import { defineStore } from "pinia";
import { watch } from "vue";

import { useErrorHubStore } from "./errorHubStore";
import { useTimingStore } from "./timingStore";
import { useApiStore } from "./apiStore";
import { useDeviceStore } from "./deviceStore";
import { useLocaleStore } from "./localeStore";
import { useStampsStore } from "./stampsStore";
import { useInteractionsStore } from "./interactionsStore";

import { deferredLogOutCookieName, deferredLogOutCookieAttributes } from "@/assets/configData/constants";
import { sessionStorageGetItem } from "@/assets/utils/storageHandlers";
import { objDeepClone } from "@/assets/utils/objectHandlers";
import * as cookies from "@/assets/utils/cookieHandlers";
import localSettings from "@/assets/static/yaml/config/localSettings.yml";

export const useMainStore = defineStore(
    {
        id      :   "mainStore",
        state   :   () => 
                    ({
                        isJustStarted   :   true,
                        isPendingLogout :   false
                    }),
        actions :   {
                        // Watcher sull'evento `network error` incaricato, all'occorrenza, di resettare tutti i watcher ed i timer della piattaforma
                        setWatcherOnNetworkError()
                        {
                            console.log("Settaggio del network error watcher");
                            this._networkErrorWatcherStopper = watch( () =>
                                useErrorHubStore().networkError, newValue =>
                                    {
                                        if (newValue)
                                        {
                                            // network error
                                            console.log("Network error rilevato dall'apposito watcher");
                                            this.prepareToUnload({}, true);
                                            // Trattandosi di un errore bloccante, si rimuove anche il watcher corrente
                                            this._networkErrorWatcherStopper();
                                            this._networkErrorWatcherStopper = null;
                                        }
                                    });
                        },

                        // Metodo invocato da `App.vue` all'evento `beforeUnload`, incaricato di eseguire tutte le operazioni preliminari alla chiusura dell'app
                        prepareToUnload(route, isInvokedForNetworkError = false)
                        {
                            console.log("********** Stop di tutte le operazioni programmate, per chiusura o per network error **********")
                            // Rimozione dei watchers attivi
                            this.resetWatcherOnAutoRelogNotice();
                            // Rimozione di tutti i timers
                            useTimingStore().prepareToUnload();
                            // Rimozione di tutti i monitoraggi sul device
                            useDeviceStore().prepareToUnload();
                            useErrorHubStore().prepareToUnload();
                            // Solo nel caso in cui si tratti di un'effettiva chiusura della piattaforma e non di un blocco generale a causa di un network error...
                            if (!isInvokedForNetworkError)
                            {
                                console.log("Piattaforma prossima alla chiusura");
                                let stampFromSessionStorage = null;
                                // Se l'utente risulta essere loggato, viene salvato l'oggetto `sessionStamp` con tutti i dati utili all'eventuale rientro in sessione
                                if (useApiStore().isUserLoggedIn)
                                {
                                    stampFromSessionStorage = sessionStorageGetItem("sessionStamp");
                                    // Se l'utente risulta essere loggato, prima di chiudere la piattaforma si salva il relativo `sessionStamp` ma solo se non è stato già salvato poichè, laddove fossimo in fase di avvio, al verificarsi di un network error, il `sessionStamp` risulterebbe già salvato.
                                    if (!stampFromSessionStorage)
                                    {
                                        useStampsStore().saveStamp("session");
                                        console.log("SAVING SESSION STAMP");
                                    }
                                }
                                if (route.name === "register")
                                {
                                    stampFromSessionStorage = sessionStorageGetItem("registerStamp");
                                    // Valgono le stesse considerazioni fatte per il salvataggio del `sessionStamp`
                                    if (!stampFromSessionStorage)
                                    {
                                        // Se l'utente (loggato o meno) sta refreshando o abbandonando la piattaforma dalla pagina `register` si salvano i dati pendenti
                                        useStampsStore().saveStamp("register");
                                        console.log("SAVING REGISTER STAMP");
                                    }
                                }
                                else
                                {
                                    stampFromSessionStorage = sessionStorageGetItem("loginStamp");
                                    // Valgono le stesse considerazioni fatte per i blocchi precedenti
                                    if (!stampFromSessionStorage)
                                    {
                                        // Se l'utente (loggato o meno) sta refreshando o abbandonando la piattaforma da qualunque altra pagina (al 99% si tratterà di `login`) si salvano i dati pendenti
                                        useStampsStore().saveStamp("login");
                                        console.log("SAVING LOGIN STAMP");
                                    }
                                }
                            }
                        },

                        // Metodo incaricato di realizzare il logout.
                        // Il presente metodo può essere invocato nei seguenti punti/casi:
                        // A - alla richiesta esplicita di logout da parte dell'utente
                        // B - al rientro in sessione da parte dell'utente, dopo aver trascorso un tempo eccessivo fuori sessione
                        // C - nel blocco di avvio della piattaforma, laddove l'utente risultasse ancora loggato, lato backend, pur avendone richiesto il logout dal lato frontend. Questa situazione ha luogo qualora la chiamata API all'endpoint "/logout" dovesse concludersi con un errore (server o rete)
                        // Il logout viene realizzato in due steps:
                        // Step 1 - chiamata API all'endpoint `logout` (logout de facto, server side)
                        // Step 2 - reset della proprietà `tokenExpiresAt` in `apiStore` (logout formale, client side)
                        // Laddove la chiamata API dello Step 1 dovesse concludersi con un errore (verosimilmente "network error") si programma un logout differito.
                        // Il logout differito consiste nella creazione/aggiornamento di un cookie (100% frontend) con valore e scadenza allineati alla proprietà  `tokenExpiresAt`. 
                        // La presenza del cookie e la sua corrispondenza con il `tokenExpiresAt` del token correntemente in uso, verificata ad ogni avvio, forza un nuovo tentativo di logout con la chiamata al presente metodo nonchè il settaggio della proprietà `excessiveExtraSessionTime` in `stampsStore`
                        logOutUser()
                        {
                            useApiStore().apiUnrejectedCall("LOGOUT").then( res =>
                                {
                                    if (res.success)
                                    {
                                        // Utente correttamente sloggato e token invalidato. Non serve sovrascrivere il cookie per il logout differito
                                        console.log("Utente correttamente sloggato e token invalidato");
                                    }
                                    else
                                    {
                                        let tokenExpiresAt = useApiStore().tokenExpiresAt;
                                        if (tokenExpiresAt)
                                        {
                                            // E' stato fatto click sull'opzione logout oppure si è appena conclusa favorevolmente la chiamata ad "APP"
                                            console.log(`E' stato fatto click sull'opzione logout oppure si è appena conclusa favorevolmente la chiamata ad "APP"`);
                                        }
                                        else
                                        {
                                            tokenExpiresAt = useStampsStore().returnStampObject("session")?.tokenExpiresAt;
                                            if (tokenExpiresAt)
                                            {
                                                // E' stato richiesto il logout a seguito del settaggio di `useStampsStore().excessiveExtraSessionTime`
                                                console.log(`E' stato richiesto il logout a seguito del settaggio di 'useStampsStore().excessiveExtraSessionTime'`);
                                            }
                                            else
                                            {
                                                // Questo blocco, in teoria, non verrà mai eseguito. Lo si lascia solo per precauzione
                                                // Impossibile settare il cookie per il logout differito
                                                console.log("Impossibile settare il cookie per il logout differito");
                                            }
                                        }
                                        if (tokenExpiresAt)
                                        {
                                            // Settaggio del cookie per il logout differito
                                            console.log("Settaggio del cookie per il logout differito");
                                            const maxAge = Math.ceil((tokenExpiresAt - Date.now()) / 1000);
                                            cookies.setCookie(deferredLogOutCookieName, tokenExpiresAt, { ...deferredLogOutCookieAttributes, "max-age" : maxAge });
                                        }
                                        useApiStore().apiNotifyErrorCall("LOGOUT", res.data);
                                    }
                                });
                            useApiStore().setStoreProperty("tokenExpiresAt", null);
                        },

                        resetWatcherOnAutoRelogNotice()
                        {
                            if (this._autoRelogNoticeWatcherStopper)
                            {
                                this._autoRelogNoticeWatcherStopper();
                                this._autoRelogNoticeWatcherStopper = null;
                                console.log("AUTORELOG NOTICE WATCHER OFF")
                            }
                        },

                        // Metodo che attiva il watcher sulla notifica della richiesta di relog automatico
                        setWatcherOnAutoRelogNotice()
                        {
                            console.log("SETTING AUTO RELOG NOTICE WATCHER")
                            // Watcher con handler non reattivo
                            this._autoRelogNoticeWatcherStopper = watch( () =>
                                useTimingStore().autoRelogNotifier, newValue =>
                                    {
                                        // Quando il notificatore nello store `timingStore` assume valore `true` è il momento di riloggare l'utente
                                        if (newValue)
                                        {
                                            useApiStore().apiUnrejectedCall("AUTORELOG")
                                                .then( res =>
                                                    {
                                                        if (res.success)
                                                        {
                                                            // Se l'autorelog ha avuto successo...
                                                            // si predispone il tutto per il prossimo relog automatico
                                                            console.log("Il relog ha avuto successo. Si risettano i timer per il prossimo")
                                                            useTimingStore().setOperationsForAutoRelog();
                                                        }
                                                        else
                                                        {
                                                            // Se l'autorelog non ha avuto successo...
                                                            // si stoppa il monitoraggio dell'attività utente
                                                            useTimingStore().inactivityTimerOff();
                                                            // E' preferibile non sloggare l'utente a seguito di autorelog non perfezionato poichè si ritroverebbe ad essere scaraventato in login page di punto in bianco (pessima UX). Alla sua prossima azione che richiederà una chiamata API gli verrà notificato lo stato di unlogged
                                                            // Si invoca il metodo incaricato di notificare al backend l'esito non positivo della chiamata fatta
                                                            useApiStore().apiNotifyErrorCall("AUTORELOG", res.data);
                                                            if (res.errorBy !== "server")
                                                            {
                                                                // Si è verificato un errore di rete
                                                                useErrorHubStore().setNetworkError();
                                                            }
                                                        }
                                                    });
                                                // Si può inserire quì il metodo `catch` laddove si voglia gestire l'eventuale errore lanciato in assenza dei dati attesi
                                        }
                                    });
                        },

                        retrieveAndApplySettings()
                        {
                            for (const setting of localSettings)
                            {
                                const { key } = setting;
                                switch (key)
                                {
                                    case "locale"   :   useLocaleStore().applyLocaleSettings();
                                                        break;   
                                }
                            }
                        },

                        // Inizializzatore di piattaforma (da eseguire solo all'avvio)
                        // - Upload della locale di fallback (PROCESSO CRITICO: IN CASO DI ERRORE NEL CARICAMENTO LA PIATTAFORMA NON PUÒ PARTIRE)
                        // - Inizializzazione dello store di memorizzazione degli errori
                        // - Recupero dei settings dal local storage e conseguente attuazione
                        // - Avvio del check e monitoraggio (debounced) del device
                        // - Avvio del watcher incaricato di monitorare il notificatore di `relog automatico`
                        // - Recupero degli eventuali stamps in session storage e settaggio degli indicatori di rientro potenziale
                        // - Settaggio del timer per la permanenza in `waiting room` nel caso di avvio standard (nuova sessione)
                        // - Chiamata API all'endpoint `/api/app` per la convalida del token e l'eventuale ottenimento dei dati per la `dashboard`
                        // - Se il token è valido si attivano i meccanismi per il monitoraggio del periodo di inattività utente e per il `relog automatico`
                        initialization()
                        {
                            console.log("Inizializzazione piattaforma");
                            useLocaleStore().loadFallbackLocale();
                            useErrorHubStore().initErrorHub();
                            this.retrieveAndApplySettings();
                            useDeviceStore().initDeviceCheck();
                            this.setWatcherOnAutoRelogNotice();
                            this.setWatcherOnNetworkError();
                            useStampsStore().retrieveStamps();
                            if (useStampsStore().excessiveExtraSessionTime)
                            {
                                // Tempo extra session eccessivo.
                                console.log("Tempo extra session eccessivo. Non si effettua la chiamata API iniziale e si forza il log out.");
                                this.logOutUser();
                                this.isJustStarted = false;
                            }
                            else
                            {
                                if (!this.anyValidStamp)
                                {
                                    // In assenza di `stamps` validi si attiva il timer per il passaggio dalla `waiting room` (nuova sessione)
                                    console.log("NESSUNO STAMP VALIDO... NUOVA SESSIONE CON PASSAGGIO IN WAITING ROOM");
                                    useTimingStore().waitingRoomOn();
                                }
                                    
                                // Blocco di codice che non richiede l'attivazione esplicita del loader, essendo coperto dai getters `isInitialized` e `keepWaiting`
                                // La chiamata per la convalida del token viene eseguita in qualunque condizione di avvio.
                                // La chiamata viene eseguita anche nel caso di `rientro da non loggato` per far fronte all'eventualità (remota) che l'utente abbia lasciato la pagina o abbia refreshato subito dopo essersi loggato o registrato, non dando il tempo al frontend di ricevere la risposta favorevole del backend ed eseguire tutte le procedure atte al salvataggio del session stamp
                                useApiStore().apiUnrejectedCall("APP")
                                    .then( res => 
                                        {
                                            if (res.success)
                                            {
                                                console.log("API CALL SUCCESS")
                                                // Il token è valido e sono stati ottenuti i dati per la dashboard
                                                // Si verifica l'eventuale presenza ed il valore del cookie per il logout differito
                                                const deferredLogOutCookie = cookies.getCookie(deferredLogOutCookieName, cookies.getAllCookies());
                                                if (deferredLogOutCookie && (deferredLogOutCookie == useApiStore().tokenExpiresAt.toString()))
                                                {
                                                    // Presenza e corrispondenza del cookie per logout differito
                                                    console.log("Presenza e corrispondenza del cookie per logout differito");
                                                    this.logOutUser();
                                                    this.isPendingLogout = true;
                                                    this.isJustStarted = false;
                                                }
                                                else
                                                {
                                                    // 2 -  Attivazione dei meccanismi per monitorare l'attività e notificare la richiesta di `relog automatico`
                                                    useTimingStore().setOperationsForAutoRelog();
                                                    // Avvio piattaforma ultimato
                                                    this.isJustStarted = false;
                                                }
                                            }
                                            else
                                            {
                                                console.log("NOT SUCCESS")
                                                // Risposta API senza successo...
                                                if (res.errorBy === "server")
                                                {
                                                    // Il server non ha convalidato il token
                                                    // Non serve neanche sloggare poichè `tokenExpiresAt` non è pervenuto
                                                    console.log("Error by server")
                                                }
                                                else
                                                {
                                                    // Si è verificato un errore di rete
                                                    console.log("Error by network")
                                                    useErrorHubStore().setNetworkError();
                                                }
                                                // Si invoca il metodo incaricato di notificare al backend l'esito non positivo della chiamata fatta
                                                useApiStore().apiNotifyErrorCall("APP", res.data);
                                                // Avvio piattaforma ultimato
                                                this.isJustStarted = false;
                                            }
                                        });
                                // Si può inserire quì il metodo `catch` laddove si voglia gestire l'eventuale errore lanciato in assenza dei dati attesi
                            }
                        },

                        // Metodo incaricato di modificare la specificata proprietà reattiva dello store `mainStore`
                        setStoreProperty(propertyName, propertyValue)
                        {
                            if (this.$state.hasOwnProperty(propertyName))
                                this[propertyName] = objDeepClone(propertyValue);
                        }
                    },
        getters :   {
                        anyValidStamp   : () => (useStampsStore().isBackToSession || useStampsStore().isBackToUnlogged),
                        isInitialized   : (state) => (!state.isJustStarted && useLocaleStore().fallbackLocaleFileLoaded && useLocaleStore().localeSettingsHandled),
                        keepWaiting     : (state) => !(state.anyValidStamp || (state.isInitialized && useTimingStore().exitWaitingRoom)),
                        isRestricted    : (state) => state.isPendingLogout || useStampsStore().excessiveExtraSessionTime,
                        isLoaderOn      : (state) => state.keepWaiting || !state.isInitialized || (useApiStore().processesRequiringLoader !== 0),
                        isOverlayOn     : (state) => state.isLoaderOn || useErrorHubStore().messageToShow || useInteractionsStore().isDatePickerOn
                    }
    });